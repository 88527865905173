import styled from 'styled-components';

export const FormBtn = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 30px;
  gap: 10px;
  height: 64px;
  background: ${(props) => props.theme.formSubmitButtonBg};
  border-radius: 8px;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;

  &.is-fetching {
    opacity: 0.5;
    background: #0c4330;
    pointer-events: none;
  }

  &.oldTemplate {
    font-weight: 800;
    height: 48px;
    margin-top: 10px;
    border-radius: 4px;
    letter-spacing: -0.5px;
  }

  @media (min-width: 400px) {
    &.oldTemplate {
      max-width: 256px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const PrimaryBtn = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 30px;
  gap: 10px;
  height: 64px;
  background: #36b389;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
`;

import styled from 'styled-components';

export const HeaderDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.headerBackground};
  padding: 30px 0;
  backdrop-filter: blur(17px);
  z-index: 10;

  &.lasta {
    padding: 20px 0;
    background: ${({ theme, templateVariant }) =>
      templateVariant && theme.headerBg[templateVariant]};
  }

  &.oldTemplate {
    background: ${(props) => props.theme.headerBackground};
    height: 48px;
    padding: 0;
    justify-content: center;
    position: sticky;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: calc(670px + 30px);
  padding: 0 15px;
  margin: 0 auto;

  @media (min-width: 991px) {
    max-width: calc(1170px + 30px);
  }

  &.oldTemplate {
    height: 100%;
    max-width: calc(992px + 30px);

    @media (min-width: 991px) {
      max-width: 992px;
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
`;

export const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 991px) {
    .oldTemplate & {
      width: 100%;
      justify-content: flex-start;
    }
  }
`;

export const HeaderCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .oldTemplate & {
    flex-direction: row-reverse;

    @media (min-width: 991px) {
      flex-direction: row;
    }
  }

  &.oldTemplate {
    @media (max-width: 990px) {
      width: 100%;
      justify-content: flex-start;
    }
  }
`;

export const HeaderNav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;

  a {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: ${(props) => props.theme.headerText};
    padding: 10px 16px;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: ${(props) => props.customColor ?? props.theme.accent};
    }

    .lasta & {
      color: ${({ theme, templateVariant }) => templateVariant && theme.headerNav[templateVariant]};

      &:hover {
        color: ${({ theme, templateVariant }) =>
          templateVariant && theme.headerNav.hoverNav[templateVariant]};
      }
    }

    @media (min-width: 991px) {
      .oldTemplate & {
        padding: 14px 16px;
        transition:
          background 0.3s ease-in-out,
          color 0.3s ease-in-out;

        &:hover {
          background: #212730;
          color: #606b71;
        }
      }
    }
  }

  @media (min-width: 991px) {
    flex-direction: row;
  }

  &.oldTemplate {
    a {
      color: #ffffff;
    }

    @media (min-width: 991px) {
      margin-left: 20px;

      a {
        font-size: 11px;
        color: #b6c7cf;
        font-weight: 400;
      }
    }

    @media (max-width: 990px) {
      width: 100%;

      ${({ langDisable }) =>
        langDisable &&
        `
        justify-content: flex-start;
        padding-top: 4px;
      `}
    }
  }
`;

export const HeaderPhone = styled.a`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  color: ${(props) => props.theme.textMainLighter};

  .lasta & {
    color: ${({ theme, templateVariant }) => templateVariant && theme.headerNav[templateVariant]};
    transition: color 0.3s ease-in-out;

    &:hover {
      color: ${({ theme, templateVariant }) =>
        templateVariant && theme.headerNav.hoverNav[templateVariant]};
    }
  }

  .oldTemplate & {
    font-size: 10px;
    color: #f3f3f3;
    font-weight: 400;
    padding: 0;
    margin-left: 5px;
    white-space: nowrap;

    @media (min-width: 991px) {
      font-size: 13px;
    }
  }
`;

export const HeaderPhoneWrapper = styled.div`
  display: flex;

  @media (min-width: 991px) {
    margin-left: auto;
  }
`;

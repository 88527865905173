import styled from 'styled-components';

export const Section = styled.section`
  overflow: hidden;
  position: relative;
  padding: 93px 0 50px;
  background-repeat: no-repeat;
  background-position: ${(props) => props.bgPosition || 'center'};
  background-size: cover;
  background-image: url(${(props) => (props.bgImage ? `${props.bgImage}.png` : '')});

  @media (min-width: 991px) {
    padding: 96px 0 48px;
  }

  &.oldTemplate {
    padding: 48px 0;
  }
`;

export const SectionWrapper = styled.div`
  width: 100%;
  max-width: calc(992px + 30px);
  padding: 0 15px;
  margin: 0 auto;

  @media (min-width: 991px) {
    max-width: 992px;
  }
`;

export const SectionHeadline = styled.h1`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: ${(props) => (props.useDarkTxt ? '#2b313b' : props.theme.heroTxt)};
  overflow-wrap: break-word;

  @media (min-width: 991px) {
    font-size: 26px;
    line-height: 32px;
    padding-top: 20px;
  }
`;

export const SectionBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;

  @media (min-width: 991px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const SectionCaption = styled.div`
  width: 100%;

  @media (min-width: 991px) {
    max-width: 450px;
  }
`;

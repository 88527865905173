import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  padding: 45px 0;
  background: ${({ theme, templateVariant }) => templateVariant && theme.faqBg[templateVariant]};

  @media (min-width: 991px) {
    padding: 90px 0;
  }
`;

export const SectionContainer = styled.div`
  width: 100%;
  max-width: calc(670px + 30px);
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 991px) {
    max-width: calc(770px + 30px);
  }
`;

export const SectionHeadline = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: ${({ theme, templateVariant }) =>
    templateVariant && theme.faqItemHeadline[templateVariant]};

  @media (min-width: 991px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

export const SectionFaqList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-top: 50px;
`;

export const FaqHeadline = styled.h5`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  user-select: none;

  @media (min-width: 991px) {
    font-size: 22px;
    line-height: 30px;
  }
`;

export const FaqHeadlineWrapper = styled.div`
  padding-right: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;

  svg {
    position: absolute;
    top: 50%;
    right: 0;
    width: 24px;
    height: 24px;
    transform: translateY(-50%) rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }
`;

export const FaqTxt = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding: 10px 0;
  margin-top: 16px;
  display: none;

  @media (min-width: 991px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const FaqItem = styled.div`
  padding: 30px;
  background: ${({ theme, templateVariant }) =>
    templateVariant && theme.faqItemBg[templateVariant]};
  border-radius: 8px;

  ${FaqTxt}, ${FaqHeadline} {
    color: ${({ theme, templateVariant }) => templateVariant && theme.faqItemTxt[templateVariant]};
  }

  ${FaqHeadlineWrapper} {
    svg {
      color: ${({ theme, templateVariant }) =>
        templateVariant && theme.faqItemTxt[templateVariant]};
    }
  }

  &.is-open {
    ${FaqTxt} {
      display: block;
    }

    ${FaqHeadlineWrapper} {
      svg {
        transform: translateY(-50%) rotate(0);
      }
    }
  }
`;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel } from '@Components/UI/Input/styled';
import { useTranslation } from 'react-i18next';
import config from '@/config.json';
import { SelectControl, SelectControlField } from './styled';

const Select = ({ label = '', register = '', name = '', options = [{}], ...props }) => {
  const { t } = useTranslation();
  const template = config.TEMPLATE;
  const oldTemplate = ['oldTemplate'].includes(template);

  return (
    <SelectControl>
      {!oldTemplate && <FormControlLabel>{t(`inputs.${name}.title`)}</FormControlLabel>}
      <SelectControlField {...register(name)} {...props} defaultValue="" required>
        {oldTemplate && (
          <option key="0" value="" hidden disabled>
            {t(`inputs.${name}.title`)}
          </option>
        )}
        {options.map((option) => (
          <option key={option.id} value={option.title}>
            {t(`inputs.${name}.${option.title}`)}
          </option>
        ))}
      </SelectControlField>
    </SelectControl>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  register: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ),
  props: PropTypes.arrayOf(PropTypes.string),
};

export default memo(Select);

import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  padding: 48px 0;
  background: ${(props) => props.theme.backgroundColor};
  margin-bottom: 20px;

  @media (min-width: 991px) {
    padding: 45px 0;
  }
`;

export const SectionWrapper = styled.div`
  width: 100%;
  max-width: calc(992px + 30px);
  padding: 0 15px;
  margin: 0 auto;

  @media (min-width: 991px) {
    max-width: 992px;
  }
`;

export const SectionBox = styled.div``;

export const GridElement = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

export const GridElementHeadline = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${(props) => props.theme.whoHeadline};
  margin-bottom: 20px;
`;

export const GridElementSubHeadline = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.theme.whoHeadline};
  margin-top: 20px;
  margin-bottom: 20px;
`;
export const GridElementSubHeadlineBottom = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.theme.whoHeadline};
  margin-top: 20px;
  font-style: italic;
  letter-spacing: -0.3px;
`;

export const GridElementText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.theme.whoTxt};

  &.oldTemplate {
    margin-bottom: 20px;
  }
`;

export const GridElementList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-bottom: 10px;
`;

export const GridElementItem = styled.li`
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.theme.whoTxt};
  list-style-type: upper-roman;
  padding-left: 0;
  list-style-position: inside;
  list-style-type: upper-roman;
`;

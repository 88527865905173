import styled from 'styled-components';

export const SelectControl = styled.div`
  position: relative;
  width: 100%;

  .lasta & {
    &:after {
      display: block;
      z-index: 4;
      content: '';
      position: absolute;
      bottom: 15px;
      right: 15px;
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.30762 2.68311L9.1915 3.56699L4.99956 7.75893L0.807617 3.56699L1.6915 2.68311L4.99956 5.99116L8.30762 2.68311Z' fill='%23AFB6D7'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      width: 10px;
      height: 10px;
    }
  }
`;

export const SelectControlField = styled.select`
  cursor: pointer;
  position: relative;
  background: #ffffff;
  border: 1px solid #afb6d7;
  border-radius: 4px;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6b7295;
  padding: 10px 30px 10px 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.30762 2.68311L9.1915 3.56699L4.99956 7.75893L0.807617 3.56699L1.6915 2.68311L4.99956 5.99116L8.30762 2.68311Z' fill='%23AFB6D7'/%3E%3C/svg%3E%0A");
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-repeat: no-repeat;
  background-size: 10px 10px;

  &:focus {
    border: 1px solid #36b289;
  }

  .oldTemplate & {
    padding-left: 18px;
    padding-right: 18px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkAxoQCjoZwKxXAAAATElEQVQ4y+2PQRIAEAwDYzzaMeNpDt7F0aBo7tLrbtsAP54kNGGoKdSuUHuMWhdq9U38rBxxW7niu/LEZ8WFD8XEoylUBBRk7/6fNR0r/ZAaG2e8FgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0wMy0yNlQxNjoxMDo1OCswMDowMK8MuBAAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMDMtMjZUMTY6MTA6NTgrMDA6MDDeUQCsAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==);
    background-repeat: no-repeat, repeat;
    background-position:
      right 0.7em top 50%,
      0 0;
    background-size:
      0.65em auto,
      100%;

    &:focus {
      border: 1px solid #afb6d7;
    }
  }
`;

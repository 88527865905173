import styled from 'styled-components';

export const Section = styled.section`
  overflow: hidden;
  position: relative;
  padding: 120px 0 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${(props) => (props.bgImage ? `${props.bgImage}_mobile.png` : '')});

  @media (min-width: 991px) {
    padding: 200px 0 100px;
    background-image: url(${(props) => (props.bgImage ? `${props.bgImage}.png` : '')});
  }
`;

export const SectionWrapper = styled.div`
  width: 100%;
  max-width: calc(670px + 30px);
  padding: 0 15px;
  margin: 0 auto;

  @media (min-width: 991px) {
    max-width: calc(1170px + 30px);
  }
`;

export const SectionHeadline = styled.h1`
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  color: ${(props) => props.theme.heroTxt};
  overflow-wrap: break-word;

  &.topWhiteText {
    color: #fff;
  }

  @media (min-width: 991px) {
    font-size: 48px;
    line-height: 56px;
    max-width: 340px;
  }
`;

export const SectionBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (min-width: 991px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const SectionCaption = styled.div`
  width: 100%;

  @media (min-width: 991px) {
    max-width: 470px;
  }
`;

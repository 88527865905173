import React from 'react';
import MainSectionForm from '@Components/UI/Form';
import config from '@/config.json';
import { FormWrapper } from './styled';

const MainScreenForm = () => {
  const template = config.TEMPLATE;
  const brandName = config.DESCRIPTOR_NAME;
  const variant = `variant_${config.VARIANT}`;
  const getCustomBGColor = (() => {
    const colorMapping = {
      'KIDGY.SERVICES': '#fff',
    };
    return colorMapping[brandName] || '';
  })();

  return (
    <FormWrapper
      customBGColor={getCustomBGColor}
      className={template}
      {...(config.VARIANT && { templateVariant: variant })}
    >
      <MainSectionForm />
    </FormWrapper>
  );
};

export default MainScreenForm;

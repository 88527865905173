import styled from 'styled-components';

export const SectionBox = styled.div``;

export const SectionWrapper = styled.div`
  width: 100%;
  max-width: calc(670px + 30px);
  padding: 0 15px;
  margin: 0 auto;

  @media (min-width: 991px) {
    max-width: 992px;
  }
`;

export const Section = styled.section`
  position: relative;
  padding: 48px 0;
  background: ${(props) => props.theme.backgroundColor};
  margin-bottom: 20px;

  @media (min-width: 991px) {
    padding: 45px 0;
  }
`;

export const GridElement = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

export const GridElementHeadline = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${(props) => props.theme.whoHeadline};
  margin-bottom: 20px;
`;

export const GridElementText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.theme.whoTxt};

  &.oldTemplate {
    margin-bottom: 20px;
  }
`;

export const GridListTop = styled.h3`
  font-size: 18px;
  font-weight: 700;
  display: block;
  margin-bottom: 10px;
  line-height: 18px;
  color: ${(props) => props.theme.whoTxt};
`;

export const GridListItem = styled.p`
  padding-left: 20px;
  margin-bottom: 10px;
  font-weight: 400;
  color: ${(props) => props.theme.whoTxt};

  b {
    font-weight: 700;
    font-size: 16px;
    display: inline-block;
    margin-bottom: 5px;
    margin-left: 5px;
  }

  &.subItem {
    padding-left: 40px;
  }
`;

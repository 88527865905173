import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (location.pathname === '/') {
      const sectionHash = location.hash;
      const section = sectionHash && document.querySelector(`${sectionHash.replace('/', '')}`);

      if (section) {
        window.scrollTo({
          top: section.offsetTop - 100,
          behavior: 'smooth',
        });
      }
    }
  }, [location.pathname]);

  return null;
};

export default ScrollToTop;

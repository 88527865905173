import styled from 'styled-components';

export const FormControl = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &.input__half:has(input[name='amount']),
  &.input__half:has(.react-datepicker-wrapper) {
    width: calc(100%);

    @media (min-width: 991px) {
      width: calc(50% - 8px);
    }
  }

  &.input__half {
    width: calc(50% - 8px);
  }

  .datepicker {
    width: 100%;
    background: #ffffff;
    border: 1px solid #afb6d7;
    border-radius: 4px;
    outline: none;
    height: 40px;
    padding: 10px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6b7295;

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #afb6d7;
    }

    &:focus {
      border: 1px solid #36b289;
    }
  }
`;

export const FormControlLabel = styled.label`
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #6b7295;
  margin-bottom: 4px;
`;

export const FormControlInput = styled.input`
  background: #ffffff;
  border: ${(props) => props.theme.inputBorder};
  border-radius: 4px;
  outline: none;
  height: 40px;
  padding: 10px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.inputColor};

  &::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #afb6d7;
  }

  &:focus {
    border: 1px solid #36b289;
  }

  .oldTemplate & {
    padding-left: 18px;
    padding-right: 18px;

    &:focus {
      border: 1px solid #afb6d7;
    }
  }
`;

export const FormControlInputError = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #ff6c63;
  margin-top: 4px;
`;

export const CheckboxControl = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: ${(props) => props.theme.formPolicyMargin};

  &.oldTemplate {
    width: 100%;
  }

  @media (min-width: 991px) {
    align-items: center;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    &.oldTemplate {
      flex-direction: column-reverse;
      align-items: start;
    }
  }
`;

export const CheckboxLabel = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.checkboxPolicyColor};
  position: relative;
  padding-left: 30px;
  cursor: pointer;

  &:after {
    border: 1px solid #36b289;
    box-shadow: 0 0 4px transparent;
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '';
    background: #fff;
    z-index: 1;
    transition:
      box-shadow 0.3s ease-in-out,
      border 0.3s ease-in-out;
  }

  ${(props) =>
    props.isError &&
    `
        &:after {
          box-shadow: 0 0 4px #ff6c63;
          border: 1px solid #ff6c63;
        }
    `}

  &:before {
    opacity: 0;
    content: '';
    z-index: 3;
    position: absolute;
    top: 10px;
    left: 10px;
    transform: translate(-50%, -50%);
    width: 11px;
    height: 8px;
    border-radius: inherit;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.82462 6.13325L10.0523 0L11 0.933373L3.82462 8L0 4.23334L0.947737 3.29997L3.82462 6.13325Z' fill='%236B7295'/%3E%3C/svg%3E%0A");
    transition: opacity 0.3s ease-in-out;
  }

  &.oldTemplate {
    font-size: 12px;
    line-height: 1.2;
    position: relative;
    display: inline-block;
    padding-left: 20px;

    &:after {
      border: 1px solid #000000;
      box-shadow: 0 0 4px transparent;
      border-radius: 3px;
      position: absolute;
      top: 0;
      left: 0;
      width: 14px;
      height: 14px;
      content: '';
      background: transparent;
      z-index: 1;
      transition:
        box-shadow 0.3s ease-in-out,
        border 0.3s ease-in-out;
    }

    &:before {
      opacity: 0;
      content: '';
      z-index: 3;
      position: absolute;
      top: 7px;
      left: 7px;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: inherit;
      background: #7cb937;
      transition: opacity 0.3s ease-in-out;
    }

    ${(props) =>
      props.isError &&
      `
        &:after {
          box-shadow: 0 0 4px #ff6c63;
          border: 1px solid #ff6c63;
        }
    `}
    a {
      text-decoration: underline;
      color: ${(props) => props.theme.checkboxPolicyColor};
    }

    @media (min-width: 991px) {
      margin: 0 -5px;
    }
  }
`;

export const CheckboxInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;

  &:checked ~ label {
    &:after {
      box-shadow: 0 0 4px #36b289;

      .oldTemplate & {
        box-shadow: none;
      }
    }

    &:before {
      opacity: 1;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  position: relative;
`;

export const CheckboxNote = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #36b289;
  text-align: left;

  @media (min-width: 991px) {
    text-align: right;
  }

  .lasta & {
    color: ${({ theme, templateVariant }) => templateVariant && theme.labelColor[templateVariant]};
  }

  .oldTemplate & {
    color: #2b313b;
    font-size: 10px;

    &:before {
      content: '* ';
      color: red;
    }
  }
`;

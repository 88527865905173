import React from 'react';
import PropTypes from 'prop-types';

export const LangIcon = (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2925 4.29297L14.7067 5.70718L7.99959 12.4143L1.29248 5.70718L2.70669 4.29297L7.99959 9.58586L13.2925 4.29297Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const CheckIcon = (props) => {
  const { template = '' } = props;
  const oldTemplate = ['oldTemplate'].includes(template);

  const FindIconFill = (tmpl) => {
    switch (tmpl) {
      case 'oldTemplate':
        return '#7cb937';
      default:
        return 'rgb(77, 255, 196)';
    }
  };

  switch (template) {
    case 'oldTemplate':
      return (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          enableBackground="new 0 0 512 512"
          xmlSpace="preserve"
          fill={FindIconFill(template)}
        >
          <g id="Page-1">
            <g id="mspy-landing-ppc-3-desktop" transform="translate(-708.000000, -4046.000000)">
              <g id="Group-26" transform="translate(360.000000, 3782.000000)">
                <g id="Group-25" transform="translate(0.000000, 158.000000)">
                  <path
                    id="yes"
                    d="M604,106c-141.2,0-256,114.8-256,256s114.8,256,256,256s256-114.8,256-256S745.2,106,604,106z
					 M751,318.8L590.2,479.6c-6.8,6.8-15.9,10.6-25.6,10.6s-18.8-3.8-25.6-10.6l-82-82c-6.8-6.8-10.6-15.9-10.6-25.6
					c0-9.7,3.8-18.8,10.6-25.6c6.8-6.8,15.9-10.6,25.6-10.6c9.7,0,18.8,3.8,25.6,10.6l56.4,56.4l135.2-135.2
					c6.8-6.8,15.9-10.6,25.6-10.6s18.8,3.8,25.6,10.6C765.1,281.7,765.1,304.6,751,318.8z"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    default:
      return (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <rect width="24" height="24" rx="12" fill={FindIconFill(template)} />
          <path
            d="M6.75 11.75L10.5 15.5L17.25 8.75"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};

export const ArrowIcon = (props) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 14.6436L12 9.64355L7 14.6436"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PhoneIcon = (props) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 384 384"
      {...props}
    >
      <g>
        <g>
          <path
            d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594
			c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448
			c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813
			C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z"
            fill="currentColor"
          />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
};

CheckIcon.propTypes = {
  template: PropTypes.string,
};

import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-width: calc(670px + 30px);
  padding: 0 15px;
  margin: 0 auto;

  @media (min-width: 991px) {
    max-width: calc(1170px + 30px);
  }
`;

export const WhatSectionBox = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 991px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const WhatSectionCaption = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 510px;

  @media (min-width: 991px) {
    padding: 30px 0;
  }
`;

export const WhatSectionHeadline = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  @media (min-width: 991px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

export const WhatSectionTxt = styled.div`
  margin-top: 30px;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    font-weight: 700;
    text-decoration: underline;
  }
`;

export const WhatSectionImage = styled.picture`
  display: block;
  width: 100%;
  max-width: 345px;
  line-height: 0;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;

  img {
    position: relative;
    width: 100%;
    height: auto;
  }

  @media (min-width: 991px) {
    margin-top: 0;
    max-width: 570px;
    margin-left: initial;
    margin-right: initial;
  }
`;

export const WhatSection = styled.section`
  position: relative;
  padding: 45px 0;
  background: ${({ theme, templateVariant }) =>
    templateVariant && theme.whatSectionBg[templateVariant]};

  @media (min-width: 991px) {
    padding: 90px 0;
  }

  ${WhatSectionHeadline} {
    color: ${({ theme, templateVariant }) =>
      templateVariant && theme.whatSectionHeader[templateVariant]};
  }

  ${WhatSectionTxt} {
    p,
    a {
      color: ${({ theme, templateVariant }) =>
        templateVariant && theme.whatSectionTxt[templateVariant]};
    }
  }
`;

export const OfferSectionBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 30px;

  @media (min-width: 991px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const OfferSectionPoints = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 991px) {
    gap: 24px;
  }
`;

export const OfferSectionPoint = styled.h4`
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  padding-left: 28px;
  position: relative;

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`;

export const OfferSectionImage = styled.picture`
  display: block;
  width: 100%;
  max-width: 345px;
  line-height: 0;
  margin-left: auto;
  margin-right: auto;

  img {
    position: relative;
    width: 100%;
    height: auto;
  }

  @media (min-width: 991px) {
    max-width: 370px;
    margin-left: initial;
    margin-right: initial;
  }
`;

export const OfferSectionHeadline = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  @media (min-width: 991px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

export const OfferSectionCaption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;

  @media (min-width: 991px) {
    max-width: 340px;
  }
`;

export const OfferSectionCaptionTxt = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  p {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const OfferSectionCaptionButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  border-radius: 30px;
  width: 100%;
  max-width: 345px;
  font-weight: 700;
  font-size: 17px;
  line-height: 18px;
  color: #fff;
  text-transform: uppercase;

  @media (min-width: 991px) {
    max-width: 227px;
  }
`;

export const OfferSection = styled.section`
  position: relative;
  padding: 45px 0;
  background: ${({ theme, templateVariant }) =>
    templateVariant && theme.offerSectionBg[templateVariant]};

  @media (min-width: 991px) {
    padding: 90px 0;
  }

  ${OfferSectionHeadline} {
    color: ${({ theme, templateVariant }) =>
      templateVariant && theme.offerSectionHeader[templateVariant]};
  }

  ${OfferSectionCaptionTxt} {
    color: ${({ theme, templateVariant }) =>
      templateVariant && theme.offerSectionTxt[templateVariant]};
  }

  ${OfferSectionPoint} {
    color: ${({ theme, templateVariant }) =>
      templateVariant && theme.offerSectionSubHeader[templateVariant]};
  }

  ${OfferSectionCaptionButton} {
    background: ${({ theme, templateVariant }) =>
      templateVariant && theme.offerSectionButton[templateVariant]};
  }
`;

export const NotesSectionItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;

  @media (min-width: 991px) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 90px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:nth-child(2n + 2) {
    @media (min-width: 991px) {
      flex-direction: row-reverse;
    }
  }
`;

export const NotesSectionImage = styled.picture`
  display: block;
  width: 100%;
  max-width: 315px;
  line-height: 0;
  margin-left: auto;
  margin-right: auto;

  img {
    position: relative;
    width: 100%;
    height: auto;
  }

  @media (min-width: 991px) {
    max-width: 445px;
    margin-left: initial;
    margin-right: initial;
  }
`;

export const NotesSectionImageBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 991px) {
    max-width: 570px;
    margin-left: initial;
    margin-right: initial;
  }
`;

export const NotesSectionCaption = styled.div`
  margin-top: 30px;
  width: 100%;

  @media (min-width: 991px) {
    margin-top: 0;
    max-width: 570px;
    padding: 0 30px;
  }
`;

export const NotesSectionCaptionHeadline = styled.h4`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  br {
    display: none;

    @media (min-width: 991px) {
      display: block;
    }
  }
`;

export const NotesSectionCaptionTxt = styled.div`
  margin-top: 30px;
  font-size: 16px;
  line-height: 22px;

  b {
    font-weight: 700;
  }
`;

export const NotesSection = styled.section`
  position: relative;
  padding: 45px 0;
  background: ${({ theme, templateVariant }) =>
    templateVariant && theme.notesSectionBg[templateVariant]};

  @media (min-width: 991px) {
    padding: 90px 0;
  }

  ${NotesSectionCaptionTxt} {
    color: ${({ theme, templateVariant }) =>
      templateVariant && theme.notesSectionTxt[templateVariant]};
  }

  ${NotesSectionCaptionHeadline} {
    color: ${({ theme, templateVariant }) =>
      templateVariant && theme.notesSectionTxt[templateVariant]};
  }
`;

import styled from 'styled-components';

export const LangCurrent = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${(props) => props.theme.headerText};
  transition: color 0.3s ease-in-out;

  &:hover {
    color: ${(props) => props.theme.accent};
  }

  .oldTemplate & {
    line-height: 0;
  }
`;

export const LangCurrentBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 16px;
  gap: 8px;
  background: transparent;
  border-radius: 8px;
  transition: background 0.3s ease-in-out;

  &.oldTemplate {
    background: #2b313b;
    border-radius: 0;
    padding: 14px 32px 14px 8px;
    margin-right: 15px;

    @media (max-width: 420px) {
      margin-right: 0;
      padding: 14px 25px 14px 8px;
    }

    &:after {
      content: '';
      position: absolute;
      left: 33px;
      top: calc(50% - 3px);
      border-style: solid;
      border-width: 4px 3px 0;
      border-color: #b6c7cf transparent transparent transparent;
      -webkit-transition: border-color 0.225s ease;
      transition: border-color 0.225s ease;
    }
  }
`;

export const LangList = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  padding: 0 16px 10px 20px;
  background: ${(props) => props.theme.languageBg};
  border-radius: 8px 0 8px 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  opacity: 0;
  visibility: hidden;
  margin-top: 10px;
  transition:
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;

  &.oldTemplate {
    align-items: flex-start;
    margin-top: 0;
    border-radius: 0 0 5px 5px;
    padding: 5px 20px 10px;
    left: 0;
    right: initial;
    background: #212730;
  }
`;

export const LangItem = styled.button`
  border: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${(props) => props.theme.headerText};
  padding: 10px 0;
  text-align: right;

  &.oldTemplate {
    display: flex;
    gap: 5px;
    padding: 0;
    margin: 5px 0;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    color: #757f84;

    &:hover {
      color: #b6c7cf;
    }
  }

  &:hover {
    color: ${(props) => props.customColor ?? props.theme.accent};
  }
`;

export const LangIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${(props) => props.theme.headerText};
  }
`;

export const LangBox = styled.div`
  position: relative;
  cursor: pointer;

  &.oldTemplate {
    margin-right: auto;
    margin-left: 10px;

    @media (min-width: 991px) {
      margin-left: 20px;
      margin-right: initial;
    }

    @media (max-width: 420px) {
      margin-left: 0px;
    }
  }

  &.is-open {
    ${LangList} {
      opacity: 1;
      visibility: visible;
    }

    ${LangCurrent} {
      color: ${(props) => props.customColor ?? props.theme.accent};
    }

    ${LangCurrentBox} {
      background: ${(props) => props.theme.languageBg};

      &.oldTemplate {
        background: #212730;

        &:after {
          border-color: #606b71 transparent transparent transparent;
        }
      }
    }

    ${LangIconWrapper} {
      svg {
        color: ${(props) => props.customColor ?? props.theme.accent};
      }
    }
  }

  &:hover {
    ${LangIconWrapper} {
      svg {
        color: ${(props) => props.customColor ?? props.theme.accent};
      }
    }

    ${LangCurrent} {
      color: ${(props) => props.customColor ?? props.theme.accent};
    }

    ${LangCurrentBox} {
      background: ${(props) => props.theme.languageBg};
    }
  }
`;

export const FlagIcon = styled.img`
  display: inline-block;
  width: 20px;
  height: 20px;
`;

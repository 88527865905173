import styled from 'styled-components';

export const SectionList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: ${(props) => props.theme.sectionTopMargin};
  gap: 8px;

  @media (min-width: 991px) {
    margin-top: 60px;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);

    &.oldTemplate {
      grid-template-columns: 1fr;
      gap: 15px;
      margin-top: 30px;
    }
  }
`;

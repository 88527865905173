import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  padding: 50px 0;
  background: ${(props) => props.customBG ?? props.theme.backgroundColorDarker};

  @media (min-width: 991px) {
    padding: 100px 0;
  }
`;

export const SectionWrapper = styled.div`
  width: 100%;
  max-width: calc(670px + 30px);
  padding: 0 15px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (min-width: 991px) {
    max-width: calc(1170px + 30px);
  }
`;

export const SectionBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const SectionHeadline = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: ${(props) => props.theme.billingHeadline};
  word-break: break-all;
`;

export const GridList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;

  @media (min-width: 991px) {
    grid-template-columns: repeat(${(props) => (props.column ? props.column : 2)}, 1fr);
  }
`;

export const GridListItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 16px;
  background: ${(props) => props.theme.billingBoxBg};
  box-shadow: ${(props) => props.theme.billingBoxShadow};
  border-radius: 8px;
  height: fit-content;
`;

export const GridItemHeadline = styled.h4`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.billingHeadline};
`;

export const GridItemText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.theme.billingTxt};

  a {
    font-weight: 700;
    text-decoration-line: underline;
    color: ${(props) => props.theme.billingTxt};
  }
`;

import styled from 'styled-components';

export const LastaSection = styled.section`
  overflow: hidden;
  position: relative;
  padding: 120px 0 45px;
  background: ${({ theme, templateVariant }) =>
    templateVariant && theme.heroSectionBg[templateVariant]};

  @media (min-width: 991px) {
    padding: 150px 0 90px;
  }
`;

export const LastaSectionWrapper = styled.div`
  width: 100%;
  max-width: calc(670px + 30px);
  padding: 0 15px;
  margin: 0 auto;

  @media (min-width: 991px) {
    max-width: calc(1170px + 30px);
  }
`;

export const LastaSectionHeadline = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  color: ${({ theme, templateVariant }) => templateVariant && theme.heroHeadline[templateVariant]};
  overflow-wrap: break-word;

  @media (min-width: 991px) {
    font-size: 40px;
    line-height: 48px;
    max-width: 470px;
  }
`;

export const LastaSectionBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (min-width: 991px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const LastaSectionCaption = styled.div`
  width: 100%;

  @media (min-width: 991px) {
    max-width: 470px;
  }
`;

export const LastaListWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  margin-top: 24px;
`;

export const LastaListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 12px;
  gap: 12px;
  border-radius: 8px;
  width: fit-content;
  color: ${({ theme, templateVariant }) =>
    templateVariant && theme.topListItemTxt[templateVariant]};
  background: ${({ theme, templateVariant }) =>
    templateVariant && theme.topListItemBg[templateVariant]};

  &.invert {
    img {
      filter: invert(1);
    }
  }
`;

export const LastaListIcon = styled.img`
  width: 16px;
  height: 16px;
`;

export const LastaImage = styled.picture`
  display: block;
  width: 100%;
  max-width: 345px;
  margin-top: 30px;
  line-height: 0;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 991px) {
    margin-top: 36px;
    margin-left: initial;
    margin-right: initial;
    max-width: 456px;
  }

  img {
    position: relative;
    width: 100%;
    height: auto;
  }
`;

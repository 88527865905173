const ListMenuOld = (listMenuType = 0) => {
  const listMenu = {
    0: [
      {
        name: 'aboutUs',
        link: '/#about',
      },
      {
        name: 'faq',
        link: '/#faq',
      },
    ],
    1: [
      {
        name: 'faq',
        link: '/#faq',
      },
      {
        name: 'terms',
        link: '/#terms',
      },
    ],
  };

  return listMenu[listMenuType];
};

export default ListMenuOld;

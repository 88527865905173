import React, { Suspense } from 'react';
import RootComponent from '@Components/RootComponent';
import RootRoutes from '@Components/RootRoutes';
import { BrowserRouter } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';
import isValidProp from '@emotion/is-prop-valid';

const App = () => {
  return (
    <StyleSheetManager shouldForwardProp={(propName) => isValidProp(propName)}>
      <BrowserRouter>
        <Suspense fallback={null}>
          <RootComponent>
            <RootRoutes />
          </RootComponent>
        </Suspense>
      </BrowserRouter>
    </StyleSheetManager>
  );
};

export default App;

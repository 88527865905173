import OldTemplate from '@Containers/Terms/OldTemplate';
import React from 'react';
import PropTypes from 'prop-types';

const TermsScreen = ({ variant = '' }) => {
  switch (variant) {
    case 'oldTemplate':
      return <OldTemplate />;
    default:
      return <></>;
  }
};

export default TermsScreen;

TermsScreen.propTypes = {
  variant: PropTypes.string,
};

export const themes = {
  default: {
    dark: {
      backgroundColor: '#151F33',
      backgroundColorDarker: '#000103',
      textMain: '#9CB5CC',
      textMainLighter: '#FFFFFF',
      accent: '#4DFFC4',
      headerBackground: 'rgba(0, 1, 3, 0.6)',
      headerText: '#b6c7cf',
      languageBg: '#000103',
      heroTxt: '#FFFFFF',
      formBgMobile: '#F2F7FF',
      formBg: '#F2F7FF',
      formRadius: '8px',
      whoHeadline: '#FFFFFF',
      whoTxt: '#9CB5CC',
      disclaimer: '#9CB5CC',
      footerTxt: '#9CB5CC',
      billingHeadline: '#FFFFFF',
      billingTxt: '#9CB5CC',
      billingBoxBg: '#151F33',
      billingBoxShadow:
        '0px 4px 16px rgba(215, 222, 241, 0.1), 0px 3px 4px rgba(215, 222, 241, 0.1)',
      topItemBg: '#FFFFFF',
      topItemBgMobile: 'rgba(4, 3, 26, 0.5)',
      topItemBgMobileBlur: 'blur(17px)',
      topItemTxt: 'rgba(43, 49, 59, 0.95)',
      topItemTxtMobile: 'rgba(242, 246, 255, 0.95)',
      iconArrowColor: '#000000',
      sectionTopMargin: '30px',
      inputBorder: '1px solid #afb6d7',
      inputColor: '#6b7295',
      textareaPlaceholderColor: '#afb6d7',
      textareaPlaceholderBorderColor: '#afb6d7',
      formPolicyMargin: '24px',
      checkboxPolicyColor: '#6b7295',
      formSubmitButtonBg: '#36b389',
      formSubmitButtonWidth: '100%',
    },
    light: {
      backgroundColor: '#FFFFFF',
      backgroundColorDarker: '#E6F2F1',
      textMain: '#2B313B',
      textMainLighter: '#2B313B',
      accent: '#36B289',
      headerBackground: '#FFFFFF',
      headerText: '#2B313B',
      languageBg: '#FFFFFF',
      heroTxt: '#2B313B',
      formBgMobile: '#FFFFFF',
      formBg: '#FFFFFF',
      formRadius: '8px',
      whoHeadline: '#2B313B',
      whoTxt: '#2B313B',
      disclaimer: '#2A313D',
      footerTxt: '#9CB5CC',
      billingHeadline: '#2B313B',
      billingTxt: '#546E7A',
      billingBoxBg: '#FFFFFF',
      billingBoxShadow:
        '0px 4px 16px rgba(215, 222, 241, 0.4), 0px 3px 4px rgba(215, 222, 241, 0.2)',
      topItemBg: '#FFFFFF',
      topItemBgMobile: '#FFFFFF',
      topItemBgMobileBlur: 'blur(17px)',
      topItemTxt: 'rgba(43, 49, 59, 0.95)',
      topItemTxtMobile: 'rgba(43, 49, 59, 0.95)',
      iconArrowColor: '#000000',
      sectionTopMargin: '30px',
      inputBorder: '1px solid #afb6d7',
      inputColor: '#6b7295',
      textareaPlaceholderColor: '#afb6d7',
      textareaPlaceholderBorderColor: '#afb6d7',
      formPolicyMargin: '24px',
      checkboxPolicyColor: '#6b7295',
      formSubmitButtonBg: '#36b389',
      formSubmitButtonWidth: '100%',
    },
    mixed: {
      backgroundColor: '#151F33',
      backgroundColorDarker: '#F2F6FC',
      textMain: '#2B313B',
      textMainLighter: '#FFFFFF',
      accent: '#4DFFC4',
      headerBackground: '#000103',
      headerText: '#B6C7CF',
      languageBg: '#000103',
      heroTxt: '#000103',
      formBgMobile: '#FFFFFF',
      formBg: '#F2F9FF',
      formRadius: '8px',
      whoHeadline: '#FFFFFF',
      whoTxt: '#9CB5CC',
      disclaimer: '#9CB5CC',
      footerTxt: '#9CB5CC',
      billingHeadline: '#2A313D',
      billingTxt: '#546E7A',
      billingBoxBg: '#FFFFFF',
      billingBoxShadow:
        '0px 4px 16px rgba(215, 222, 241, 0.4), 0px 3px 4px rgba(215, 222, 241, 0.2)',
      topItemBg: '#FFFFFF',
      topItemBgMobile: '#FFFFFF',
      topItemBgMobileBlur: 'blur(17px)',
      topItemTxt: 'rgba(43, 49, 59, 0.95)',
      topItemTxtMobile: 'rgba(43, 49, 59, 0.95)',
      iconArrowColor: '#000000',
      sectionTopMargin: '30px',
      inputBorder: '1px solid #afb6d7',
      inputColor: '#6b7295',
      textareaPlaceholderColor: '#afb6d7',
      textareaPlaceholderBorderColor: '#afb6d7',
      formPolicyMargin: '24px',
      checkboxPolicyColor: '#6b7295',
      formSubmitButtonBg: '#36b389',
      formSubmitButtonWidth: '100%',
    },
  },
  oldTemplate: {
    dark: {
      font: 'Open Sans',
      backgroundColor: '#FFFFFF',
      backgroundColorDarker: '#000103',
      textMain: '#9CB5CC',
      textMainLighter: '#FFFFFF',
      accent: '#b6c7cf',
      headerBackground: '#2b313b',
      headerTextMob: '#FFFFFF',
      headerText: '#b6c7cf',
      languageBg: 'rgba(42,49,60,.95)',
      heroTxt: '#2b313b',
      formBgMobile: 'rgba(255,255,255,.7)',
      formBg: '#F2F7FF',
      formRadius: '8px',
      whoHeadline: '#000000',
      whoTxt: '#000000',
      disclaimer: '#9CB5CC',
      footerTxt: '#9CB5CC',
      footerAddressTxt: '#848a93',
      billingHeadline: '#000000',
      billingTxt: '#000000',
      billingBoxBg: '#151F33',
      billingBoxShadow:
        '0px 4px 16px rgba(215, 222, 241, 0.1), 0px 3px 4px rgba(215, 222, 241, 0.1)',
      topItemBg: 'transparent',
      topItemBgMobile: 'transparent',
      topItemBgMobileBlur: 'none',
      topItemTxt: '#000000',
      topItemTxtMobile: '#000000',
      iconArrowColor: '#FFFFFF',
      sectionTopMargin: '18px',
      inputBorder: '1px solid rgba(43,49,59,.32)',
      inputColor: 'rgb(120, 123, 128)',
      textareaPlaceholderColor: 'rgb(120, 123, 128)',
      textareaPlaceholderBorderColor: 'rgba(43,49,59,.32)',
      formPolicyMargin: '10px',
      checkboxPolicyColor: '#000000',
      formSubmitButtonBg: '#7cb937;',
      formSubmitButtonWidth: '256px',
    },
    light: {
      font: 'Open Sans',
      backgroundColor: '#FFFFFF',
      backgroundColorDarker: '#E6F2F1',
      textMain: '#2B313B',
      textMainLighter: '#FFFFFF',
      accent: '#b6c7cf',
      headerBackground: '#2b313b',
      headerTextMob: '#FFFFFF',
      headerText: '#757f84',
      languageBg: 'rgba(42,49,60,.95)',
      heroTxt: '#FFFFFF',
      formBgMobile: 'rgba(255,255,255,.7)',
      formBg: '#FFFFFF',
      formRadius: '8px',
      whoHeadline: '#2B313B',
      whoTxt: '#2B313B',
      disclaimer: '#2A313D',
      footerTxt: '#9CB5CC',
      billingHeadline: '#2B313B',
      billingTxt: '#546E7A',
      billingBoxBg: '#FFFFFF',
      billingBoxShadow:
        '0px 4px 16px rgba(215, 222, 241, 0.4), 0px 3px 4px rgba(215, 222, 241, 0.2)',
      topItemBg: 'transparent',
      topItemBgMobile: 'transparent',
      topItemBgMobileBlur: 'none',
      topItemTxt: '#FFFFFF',
      topItemTxtMobile: '#FFFFFF',
      iconArrowColor: '#000000',
      sectionTopMargin: '18px',
      inputBorder: '1px solid rgba(43,49,59,.32)',
      inputColor: 'rgb(120, 123, 128)',
      textareaPlaceholderColor: 'rgb(120, 123, 128)',
      textareaPlaceholderBorderColor: 'rgba(43,49,59,.32)',
      formPolicyMargin: '10px',
      checkboxPolicyColor: '#000000',
      formSubmitButtonBg: '#7cb937;',
      formSubmitButtonWidth: '256px',
    },
    mixed: {
      font: 'Open Sans',
      backgroundColor: '#2b313b',
      backgroundColorDarker: '#F2F6FC',
      textMain: '#2B313B',
      textMainLighter: '#FFFFFF',
      accent: '#b6c7cf',
      headerBackground: '#000103',
      headerTextMob: '#FFFFFF',
      headerText: '#B6C7CF',
      languageBg: 'rgba(42,49,60,.95)',
      heroTxt: '#000103',
      formBgMobile: 'rgba(255,255,255,.7)',
      formBg: '#F2F9FF',
      formRadius: '8px',
      whoHeadline: '#FFFFFF',
      whoTxt: '#9CB5CC',
      disclaimer: '#9CB5CC',
      footerTxt: '#9CB5CC',
      billingHeadline: '#2A313D',
      billingTxt: '#546E7A',
      billingBoxBg: '#FFFFFF',
      billingBoxShadow:
        '0px 4px 16px rgba(215, 222, 241, 0.4), 0px 3px 4px rgba(215, 222, 241, 0.2)',
      topItemBg: 'transparent',
      topItemBgMobile: 'transparent',
      topItemBgMobileBlur: 'none',
      topItemTxt: '#FFFFFF',
      topItemTxtMobile: '#FFFFFF',
      iconArrowColor: '#FFFFFF',
      sectionTopMargin: '18px',
      inputBorder: '1px solid rgba(43,49,59,.32)',
      inputColor: 'rgb(120, 123, 128)',
      textareaPlaceholderColor: 'rgb(120, 123, 128)',
      textareaPlaceholderBorderColor: 'rgba(43,49,59,.32)',
      formPolicyMargin: '10px',
      checkboxPolicyColor: '#000000',
      formSubmitButtonBg: '#7cb937;',
      formSubmitButtonWidth: '256px',
    },
  },
  lasta: {
    light: {
      font: 'DM Sans',
      formRadius: '16px',
      accent: '#43BB5E',
      privacyBg: {
        variant_1: '#ffffff',
        variant_2: '#ffffff',
        variant_3: '#ffffff',
      },
      privacyTxt: {
        variant_1: '#2B313B',
        variant_2: '#2B313B',
        variant_3: '#2B313B',
      },
      topListItemBg: {
        variant_1: '#DBEEC8',
        variant_2: '#DBEEC8',
        variant_3: '#DBEEC8',
      },
      topListItemTxt: {
        variant_1: '#000000',
        variant_2: '#000000',
        variant_3: '#000000',
      },
      labelColor: {
        variant_1: '#2B313B',
        variant_2: '#2B313B',
        variant_3: '#2B313B',
      },
      formBorderColor: {
        variant_1: '#AFB6D7',
        variant_2: '#AFB6D7',
        variant_3: '#AFB6D7',
      },
      placeholderColor: {
        variant_1: '#A7A7A7',
        variant_2: '#A7A7A7',
        variant_3: '#A7A7A7',
      },
      formInputColor: {
        variant_1: '#2B313B',
        variant_2: '#2B313B',
        variant_3: '#2B313B',
      },
      formBtnBg: {
        variant_1: '#43BB5E',
        variant_2: '#43BB5E',
        variant_3: '#43BB5E',
      },
      inputsBg: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#FFFFFF',
      },
      heroSectionBg: {
        variant_1: '#fff',
        variant_2: '#F8F6F0',
        variant_3: '#fff',
      },
      headerBg: {
        variant_1: '#fff',
        variant_2: '#fff',
        variant_3: '#1A5334',
      },
      formBgColor: {
        variant_1: '#F8F6F0',
        variant_2: '#FFFFFF',
        variant_3: '#F8F6F0',
      },
      headerNav: {
        variant_1: '#2B313B',
        variant_2: '#2B313B',
        variant_3: '#FFFFFF',
        hoverNav: {
          variant_1: '#30BF70',
          variant_2: '#30BF70',
          variant_3: '#30BF70',
        },
      },
      logoColor: {
        variant_1: '#2F8957',
        variant_2: '#2F8957',
        variant_3: '#FFFFFF',
      },
      burger: {
        variant_1: '#1A5334',
        variant_2: '#1A5334',
        variant_3: '#FFFFFF',
      },
      burgerOpen: {
        variant_1: '#1A5334',
        variant_2: '#1A5334',
        variant_3: '#316448',
      },
      burgerOpenNav: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#FFFFFF',
      },
      footerBg: {
        variant_1: '#F8F6F0',
        variant_2: '#FFFFFF',
        variant_3: '#1A5334',
      },
      footerTxtColor: {
        variant_1: '#2B313B',
        variant_2: '#2B313B',
        variant_3: '#FFFFFF',
      },
      footerLogoColor: {
        variant_1: '#2F8957',
        variant_2: '#2F8957',
        variant_3: '#FFFFFF',
      },
      faqBg: {
        variant_1: '#FFFFFF',
        variant_2: '#F8F6F0',
        variant_3: '#FFFFFF',
      },
      faqItemBg: {
        variant_1: '#F8F6F0',
        variant_2: '#FFFFFF',
        variant_3: '#F8F6F0',
      },
      faqItemHeadline: {
        variant_1: '#000000',
        variant_2: '#000000',
        variant_3: '#000000',
      },
      faqItemTxt: {
        variant_1: '#2B313B',
        variant_2: '#2B313B',
        variant_3: '#2B313B',
      },
      whatSectionBg: {
        variant_1: '#F8F6F0',
        variant_2: '#FFFFFF',
        variant_3: '#FFFFFF',
      },
      whatSectionHeader: {
        variant_1: '#000000',
        variant_2: '#000000',
        variant_3: '#000000',
      },
      whatSectionTxt: {
        variant_1: '#2B313B',
        variant_2: '#2B313B',
        variant_3: '#2B313B',
      },
      offerSectionBg: {
        variant_1: '#FFFFFF',
        variant_2: '#F8F6F0',
        variant_3: '#F8F6F0',
      },
      offerSectionButton: {
        variant_1: '#43BB5E',
        variant_2: '#43BB5E',
        variant_3: '#43BB5E',
      },
      offerSectionHeader: {
        variant_1: '#000000',
        variant_2: '#000000',
        variant_3: '#000000',
      },
      offerSectionSubHeader: {
        variant_1: '#090909',
        variant_2: '#090909',
        variant_3: '#090909',
      },
      offerSectionTxt: {
        variant_1: '#2B313B',
        variant_2: '#2B313B',
        variant_3: '#2B313B',
      },
      notesSectionBg: {
        variant_1: '#F8F6F0',
        variant_2: '#FFFFFF',
        variant_3: '#FFFFFF',
      },
      notesSectionTxt: {
        variant_1: '#2B313B',
        variant_2: '#2B313B',
        variant_3: '#2B313B',
      },
      heroHeadline: {
        variant_1: '#000000',
        variant_2: '#000000',
        variant_3: '#000000',
      },
    },
    mid: {
      font: 'DM Sans',
      formRadius: '16px',
      accent: '#43BB5E',
      privacyBg: {
        variant_1: '#ffffff',
        variant_2: '#ffffff',
        variant_3: '#ffffff',
        variant_4: '#ffffff',
      },
      privacyTxt: {
        variant_1: '#2B313B',
        variant_2: '#2B313B',
        variant_3: '#2B313B',
        variant_4: '#2B313B',
      },
      topListItemBg: {
        variant_1: '#DBEEC8',
        variant_2: '#DBEEC8',
        variant_3: '#DBEEC8',
        variant_4: '#DBEEC8',
      },
      topListItemTxt: {
        variant_1: '#000000',
        variant_2: '#000000',
        variant_3: '#000000',
        variant_4: '#000000',
      },
      labelColor: {
        variant_1: '#2B313B',
        variant_2: '#2B313B',
        variant_3: '#2B313B',
        variant_4: '#2B313B',
      },
      formBorderColor: {
        variant_1: '#AFB6D7',
        variant_2: '#AFB6D7',
        variant_3: '#AFB6D7',
        variant_4: '#AFB6D7',
      },
      placeholderColor: {
        variant_1: '#A7A7A7',
        variant_2: '#A7A7A7',
        variant_3: '#A7A7A7',
        variant_4: '#A7A7A7',
      },
      formInputColor: {
        variant_1: '#2B313B',
        variant_2: '#2B313B',
        variant_3: '#2B313B',
        variant_4: '#2B313B',
      },
      formBtnBg: {
        variant_1: '#43BB5E',
        variant_2: '#43BB5E',
        variant_3: '#43BB5E',
        variant_4: '#43BB5E',
      },
      inputsBg: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#FFFFFF',
        variant_4: '#FFFFFF',
      },
      heroSectionBg: {
        variant_1: '#fff',
        variant_2: '#F8F6F0',
        variant_3: '#fff',
        variant_4: '#1A5334',
      },
      headerBg: {
        variant_1: '#fff',
        variant_2: '#fff',
        variant_3: '#1A5334',
        variant_4: '#1A5334',
      },
      formBgColor: {
        variant_1: '#F8F6F0',
        variant_2: '#FFFFFF',
        variant_3: '#F8F6F0',
        variant_4: '#FFFFFF',
      },
      headerNav: {
        variant_1: '#2B313B',
        variant_2: '#2B313B',
        variant_3: '#FFFFFF',
        variant_4: '#FFFFFF',
        hoverNav: {
          variant_1: '#30BF70',
          variant_2: '#30BF70',
          variant_3: '#30BF70',
          variant_4: '#30BF70',
        },
      },
      logoColor: {
        variant_1: '#2F8957',
        variant_2: '#2F8957',
        variant_3: '#FFFFFF',
        variant_4: '#FFFFFF',
      },
      burger: {
        variant_1: '#1A5334',
        variant_2: '#1A5334',
        variant_3: '#FFFFFF',
        variant_4: '#FFFFFF',
      },
      burgerOpen: {
        variant_1: '#1A5334',
        variant_2: '#1A5334',
        variant_3: '#316448',
        variant_4: '#316448',
      },
      burgerOpenNav: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#FFFFFF',
        variant_4: '#FFFFFF',
      },
      footerBg: {
        variant_1: '#1A5334',
        variant_2: '#FFFFFF',
        variant_3: '#1A5334',
        variant_4: '#1A5334',
      },
      footerTxtColor: {
        variant_1: '#FFFFFF',
        variant_2: '#2B313B',
        variant_3: '#FFFFFF',
        variant_4: '#FFFFFF',
      },
      footerLogoColor: {
        variant_1: '#FFFFFF',
        variant_2: '#2F8957',
        variant_3: '#FFFFFF',
        variant_4: '#FFFFFF',
      },
      faqBg: {
        variant_1: '#FFFFFF',
        variant_2: '#1A5334',
        variant_3: '#FFFFFF',
        variant_4: '#F8F6F0',
      },
      faqItemBg: {
        variant_1: '#F8F6F0',
        variant_2: 'rgba(255, 255, 255, 0.15)',
        variant_3: '#F8F6F0',
        variant_4: '#FFFFFF',
      },
      faqItemHeadline: {
        variant_1: '#000000',
        variant_2: '#FFFFFF',
        variant_3: '#000000',
        variant_4: '#2B313B',
      },
      faqItemTxt: {
        variant_1: '#2B313B',
        variant_2: '#FFFFFF',
        variant_3: '#2B313B',
        variant_4: '#2B313B',
      },
      whatSectionBg: {
        variant_1: '#1A5334',
        variant_2: '#1A5334',
        variant_3: '#F8F6F0',
        variant_4: '#FFFFFF',
      },
      whatSectionHeader: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#000000',
        variant_4: '#000000',
      },
      whatSectionTxt: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#2B313B',
        variant_4: '#2B313B',
      },
      offerSectionBg: {
        variant_1: '#FFFFFF',
        variant_2: '#F8F6F0',
        variant_3: '#174B2F',
        variant_4: '#F8F6F0',
      },
      offerSectionButton: {
        variant_1: '#43BB5E',
        variant_2: '#43BB5E',
        variant_3: '#43BB5E',
        variant_4: '#43BB5E',
      },
      offerSectionHeader: {
        variant_1: '#000000',
        variant_2: '#000000',
        variant_3: '#FFFFFF',
        variant_4: '#000000',
      },
      offerSectionSubHeader: {
        variant_1: '#090909',
        variant_2: '#090909',
        variant_3: '#FFFFFF',
        variant_4: '#090909',
      },
      offerSectionTxt: {
        variant_1: '#2B313B',
        variant_2: '#2B313B',
        variant_3: '#FFFFFF',
        variant_4: '#2B313B',
      },
      notesSectionBg: {
        variant_1: '#1A5334',
        variant_2: '#FFFFFF',
        variant_3: '#F8F6F0',
        variant_4: '#1A5334',
      },
      notesSectionTxt: {
        variant_1: '#FFFFFF',
        variant_2: '#2B313B',
        variant_3: '#2B313B',
        variant_4: '#FFFFFF',
      },
      heroHeadline: {
        variant_1: '#000000',
        variant_2: '#000000',
        variant_3: '#000000',
        variant_4: '#FFFFFF',
      },
    },
    dark: {
      font: 'DM Sans',
      formRadius: '16px',
      accent: '#43BB5E',
      privacyBg: {
        variant_1: '#15422A',
        variant_2: '#15422A',
        variant_3: '#15422A',
      },
      privacyTxt: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#FFFFFF',
      },
      topListItemBg: {
        variant_1: '#DBEEC8',
        variant_2: 'rgba(219, 238, 200, 0.15)',
        variant_3: 'rgba(219, 238, 200, 0.15)',
      },
      topListItemTxt: {
        variant_1: '#000000',
        variant_2: '#FFFFFF',
        variant_3: '#FFFFFF',
      },
      labelColor: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#2B313B',
      },
      formBorderColor: {
        variant_1: '#123824',
        variant_2: '#40614F',
        variant_3: '#AFB6D7',
      },
      placeholderColor: {
        variant_1: 'rgba(255, 255, 255, 0.5)',
        variant_2: 'rgba(255, 255, 255, 0.5)',
        variant_3: '#A7A7A7',
      },
      formInputColor: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#2B313B',
      },
      formBtnBg: {
        variant_1: '#FF7E56',
        variant_2: '#FF7E56',
        variant_3: '#43BB5E',
      },
      inputsBg: {
        variant_1: '#123824',
        variant_2: '#40614F',
        variant_3: '#FFFFFF',
      },
      heroSectionBg: {
        variant_1: '#1A5334',
        variant_2: '#123A24',
        variant_3: '#15422A',
      },
      headerBg: {
        variant_1: '#174B2F',
        variant_2: '#123A24',
        variant_3: '#1A5334',
      },
      formBgColor: {
        variant_1: '#15422A',
        variant_2: '#2A4E3A',
        variant_3: '#F8F6F0',
      },
      headerNav: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#FFFFFF',
        hoverNav: {
          variant_1: '#30BF70',
          variant_2: '#30BF70',
          variant_3: '#30BF70',
        },
      },
      logoColor: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#FFFFFF',
      },
      burger: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#FFFFFF',
      },
      burgerOpen: {
        variant_1: '#316448',
        variant_2: '#316448',
        variant_3: '#316448',
      },
      burgerOpenNav: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#FFFFFF',
      },
      footerBg: {
        variant_1: '#1A5334',
        variant_2: '#1A5334',
        variant_3: '#1A5334',
      },
      footerTxtColor: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#FFFFFF',
      },
      footerLogoColor: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#FFFFFF',
      },
      faqBg: {
        variant_1: '#174B2F',
        variant_2: '#123A24',
        variant_3: '#174B2F',
      },
      faqItemBg: {
        variant_1: 'rgba(0, 0, 0, 0.2)',
        variant_2: 'rgba(255, 255, 255, 0.15)',
        variant_3: '#FFFFFF',
      },
      faqItemHeadline: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#FFFFFF',
      },
      faqItemTxt: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#2B313B',
      },
      whatSectionBg: {
        variant_1: '#15422A',
        variant_2: '#1A5334',
        variant_3: '#1A5334',
      },
      whatSectionHeader: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#FFFFFF',
      },
      whatSectionTxt: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#FFFFFF',
      },
      offerSectionBg: {
        variant_1: '#1A5334',
        variant_2: '#123A24',
        variant_3: '#FFFFFF',
      },
      offerSectionButton: {
        variant_1: '#FF7E56',
        variant_2: '#FF7E56',
        variant_3: '#43BB5E',
      },
      offerSectionHeader: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#000000',
      },
      offerSectionSubHeader: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#090909',
      },
      offerSectionTxt: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#2B313B',
      },
      notesSectionBg: {
        variant_1: '#15422A',
        variant_2: '#1A5334',
        variant_3: '#1A5334',
      },
      notesSectionTxt: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#FFFFFF',
      },
      heroHeadline: {
        variant_1: '#FFFFFF',
        variant_2: '#FFFFFF',
        variant_3: '#FFFFFF',
      },
    },
  },
};

import React from 'react';

import MainScreenElement from '@Components/MainScreenElement';
import config from '@/config.json';
import { useTranslation } from 'react-i18next';
import listElements from './listElements.json';
import listElementsOld from './listElementsOld.json';
import { SectionList } from './styled';

const MainScreenList = () => {
  const { i18n } = useTranslation();

  const template = config?.TEMPLATE;
  const oldTemplate = ['oldTemplate'].includes(template);
  const templateType = config?.MAIN_SECTION_TYPE;
  const descriptorName = config?.DESCRIPTOR_TAG;

  const getRenderHeaderType = (element) => {
    if (
      (i18n.language === 'de' && ['eyectrl.app', 'ctrl-soft.com'].includes(descriptorName)) ||
      (i18n.language === 'fr' && ['eyectrl.app', 'ctrl-soft.com'].includes(descriptorName)) ||
      (i18n.language === 'es' && ['eyectrl.app', 'ctrl-soft.com'].includes(descriptorName)) ||
      (i18n.language === 'pt' && ['eyectrl.app', 'ctrl-soft.com'].includes(descriptorName))
    ) {
      return element.headline;
    }
    switch (templateType) {
      case '2':
        return element.headline_2;
      case '3':
        return element.headline_3;
      case '4':
        return element.headline_4;
      default:
        return element.headline;
    }
  };

  return (
    <SectionList className={oldTemplate && template}>
      {(oldTemplate ? listElementsOld : listElements).map((element) => (
        <MainScreenElement
          key={element.id}
          headline={getRenderHeaderType(element)}
          text={element.description}
        />
      ))}
    </SectionList>
  );
};

export default MainScreenList;

import styled from 'styled-components';

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: calc(670px + 30px);
  padding: 0 15px;
  margin: 0 auto;

  @media (min-width: 991px) {
    max-width: calc(1170px + 30px);
  }
`;

export const ContentTxt = styled.div`
  width: 100%;
  max-width: calc(670px + 30px);
  padding: 0 15px;
  margin: 0 auto;
  color: rgba(43, 49, 59, 0.95);

  @media (min-width: 991px) {
    max-width: calc(1170px + 30px);
  }

  h2,
  h3,
  h4 {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(43, 49, 59, 0.95);
    margin-bottom: 20px;
  }

  .pre-title {
    margin-bottom: 10px;
    font-weight: 700;
    display: block;
  }

  ul {
    display: flex;
    flex-direction: column;

    &.list-dote {
      li {
        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #000;
          left: 0;
          top: 9px;
        }
      }
    }

    li {
      position: relative;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(43, 49, 59, 0.95);
      margin: 8px 0;
      padding-left: 20px;
    }
  }

  a {
    color: rgba(43, 49, 59, 0.95);
    font-weight: 700;
    text-decoration: underline;
  }
`;

export const Content = styled.div`
  position: relative;
  padding: 140px 0 50px;
  background: #fff;

  @media (min-width: 991px) {
    padding: 150px 0;
  }

  &.lasta {
    padding: 120px 0 30px;
    background: ${({ theme, templateVariant }) =>
      templateVariant && theme.privacyBg[templateVariant]};

    @media (min-width: 991px) {
      padding: 130px 0 60px;
    }

    ${ContentWrapper} {
      @media (min-width: 991px) {
        max-width: calc(770px + 30px);
      }
    }

    ${ContentTxt} {
      color: ${({ theme, templateVariant }) =>
        templateVariant && theme.privacyTxt[templateVariant]};

      p,
      ul li,
      a {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: ${({ theme, templateVariant }) =>
          templateVariant && theme.privacyTxt[templateVariant]};
      }

      b {
        font-weight: 700;
      }

      ul.list-dote li {
        position: relative;
        margin-top: 0;
        margin-bottom: 30px;

        &:before {
          position: absolute;
          top: 9px;
          left: 0;
          width: 4px;
          height: 4px;
          content: '';
          border-radius: 50%;
          background: ${({ theme, templateVariant }) =>
            templateVariant && theme.privacyTxt[templateVariant]};
        }
      }

      h2,
      h3,
      h4 {
        text-align: left;
        margin: 30px 0;
      }

      .main-headline {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        color: ${({ theme, templateVariant }) =>
          templateVariant && theme.privacyTxt[templateVariant]};
        margin-bottom: 30px;

        @media (min-width: 991px) {
          font-size: 40px;
          line-height: 48px;
          margin-bottom: 60px;
        }
      }
    }
  }

  &.oldTemplate {
    padding-top: 48px;
    padding-bottom: 48px;
    @media (min-width: 991px) {
      padding-top: 45px;
      padding-bottom: 45px;
    }

    ${ContentWrapper} {
      padding: 0;
      @media (min-width: 991px) {
        max-width: 992px;
      }
    }
  }
`;

import React from 'react';
import { useTranslation } from 'react-i18next';

import config from '@/config.json';
import {
  GridElement,
  GridElementHeadline,
  GridElementItem,
  GridElementList,
  GridElementSubHeadline,
  GridElementText,
  Section,
  SectionBox,
  SectionWrapper,
} from './styled';

const DefaultTemplate = () => {
  const { t } = useTranslation();

  return (
    <Section id="about">
      <SectionWrapper>
        <SectionBox>
          <GridElement>
            <GridElementHeadline>{t(`whoSection.headline`)}</GridElementHeadline>
            <GridElementText
              dangerouslySetInnerHTML={{
                __html: t('whoSection.txt1', {
                  descriptor: config.TRADE_NAME.toLowerCase(),
                  descriptor_domain: config.DESCRIPTOR_DOMAIN.toLowerCase(),
                }),
              }}
            />
            <GridElementText>{t(`whoSection.txt2`)}</GridElementText>
            <GridElementText>{t(`whoSection.txt3`)}</GridElementText>
          </GridElement>
          <GridElement>
            <GridElementSubHeadline>{t(`whoSection.subHeadline1`)}</GridElementSubHeadline>
            <GridElementList>
              <GridElementItem>{t(`whoSection.list1`)}</GridElementItem>
              <GridElementItem>{t(`whoSection.list2`)}</GridElementItem>
              <GridElementItem>{t(`whoSection.list3`)}</GridElementItem>
              <GridElementItem>{t(`whoSection.list4`)}</GridElementItem>
              <GridElementItem>{t(`whoSection.list5`)}</GridElementItem>
            </GridElementList>
            <GridElementText>
              {t(`whoSection.txt4`, {
                descriptor: config.TRADE_NAME.toLowerCase(),
                descriptor_domain: config.DESCRIPTOR_DOMAIN.toLowerCase(),
              })}
            </GridElementText>
          </GridElement>
          <GridElement>
            <GridElementSubHeadline>
              {t(`whoSection.subHeadline2`, {
                descriptor: config.TRADE_NAME,
                descriptor_domain: config.DESCRIPTOR_DOMAIN,
              })}
            </GridElementSubHeadline>
            <GridElementText>
              {t(`whoSection.txt5`, {
                descriptor: config.TRADE_NAME.toLowerCase(),
                descriptor_domain: config.DESCRIPTOR_DOMAIN.toLowerCase(),
              })}
            </GridElementText>
          </GridElement>
          <GridElement>
            <GridElementSubHeadline>{t(`whoSection.subHeadline3`)}</GridElementSubHeadline>
            <GridElementText>{t(`whoSection.txt6`)}</GridElementText>
          </GridElement>
        </SectionBox>
      </SectionWrapper>
    </Section>
  );
};

export default DefaultTemplate;

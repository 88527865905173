import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import config from '@/config.json';
import { ArrowIcon } from '@Components/UI/Icons';
import {
  FaqHeadline,
  FaqHeadlineWrapper,
  FaqItem,
  FaqTxt,
  Section,
  SectionContainer,
  SectionFaqList,
  SectionHeadline,
} from './styled';
import billing from './billingQuestions.json';
import other from './otherQuestions.json';

const OldTemplate = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const templateType = config.FAQ_SECTION_TYPE === '2' && currentLanguage === 'en' ? '2' : '1';
  const descriptorName = config?.DESCRIPTOR_TAG.toLowerCase();

  const getTxt = (descriptor) => {
    if (
      [
        'appups.art',
        'appups.info',
        'appups.me',
        'billctrl.app',
        'bill-msp.app',
        'cltrl-bill.info',
        'ctrl-msp.app',
        'ctrlsoft.app',
        'fastsupport.app',
        'fulldaysupport.com',
        'getonlinesupport.pro',
        'getprosupport.com',
        'isupportapp.co',
        'itsupportapp.pro',
        'kidapp.info',
        'kidctrl.app',
        'm-secure.app',
        'm-app.net',
        'mbill.app',
        'm-bill.info',
        'mcntrl.app',
        'mcontrolapp.com',
        'quicksupport.pro',
        'mkid.app',
        'mkidctrl.app',
        'mkidsecure.com',
        'm-locate.app',
        'msafety.app',
        'msbill.app',
        'msp-kid.app',
        'msp-app.net',
        'msp-bill.info',
        'msp-control.app',
        'mspkid.app',
        'mspsoft.app',
        'my-bill.app',
        'my-kid.app',
        'onlinecontrol.app',
        'onlinesecure.app',
        'parent-msp.app',
        'paysoft.app',
        'pcsupportapp.com',
        'profbill.app',
        'qualitysupport.live',
        'mpotect.app',
        'secure-msp.app',
        'softbill.app',
        'softctrl.app',
        'supportapp24.com',
        'supportapphelp.pro',
        'supportapponline.com',
        'topsupportapp.com',
        'websupportapp.com',
        'wesupportapp.com',
      ].includes(descriptor)
    ) {
      return '_appups.';
    }
    if (['ctrl-soft.com', 'eyectrl.app'].includes(descriptor)) {
      return '_ctrl.';
    }
    if (['guard-tools.net', 'm-services.app'].includes(descriptor)) {
      return '_guard.';
    }
    return '';
  };

  return (
    <Section id="faq">
      <SectionContainer>
        <SectionHeadline>
          {t(`oldTemplate.faq.type${templateType}.billingHeadline`)}
        </SectionHeadline>
        <SectionFaqList>
          {billing?.map((item) => {
            const [isOpen, setIsOpen] = useState(false);
            return (
              <FaqItem key={item.id} className={isOpen && 'is-open'}>
                <FaqHeadlineWrapper onClick={() => setIsOpen(!isOpen)}>
                  <FaqHeadline
                    dangerouslySetInnerHTML={{
                      __html: t(
                        `oldTemplate.faq.type${templateType}.billingList.${getTxt(descriptorName)}${
                          item.headline
                        }`,
                        {
                          descriptor_email: config.MAILER_SUPPORT_EMAIL.toLowerCase(),
                          descriptor: config.TRADE_NAME.toLowerCase(),
                          descriptor_domain: config.DESCRIPTOR_DOMAIN.toLowerCase(),
                        },
                      ),
                    }}
                  />
                  <ArrowIcon />
                </FaqHeadlineWrapper>
                <FaqTxt
                  dangerouslySetInnerHTML={{
                    __html: t(
                      `oldTemplate.faq.type${templateType}.billingList.${getTxt(descriptorName)}${
                        item.txt
                      }`,
                      {
                        descriptor_email: config.MAILER_SUPPORT_EMAIL.toLowerCase(),
                        descriptor: config.TRADE_NAME.toLowerCase(),
                        descriptor_domain: config.DESCRIPTOR_DOMAIN.toLowerCase(),
                      },
                    ),
                  }}
                />
              </FaqItem>
            );
          })}
        </SectionFaqList>
      </SectionContainer>
      <SectionContainer className="bottom">
        <SectionHeadline>{t(`oldTemplate.faq.type${templateType}.otherHeadline`)}</SectionHeadline>
        <SectionFaqList>
          {other?.map((item) => {
            const [isOpen, setIsOpen] = useState(false);
            const exist = i18n.exists(
              `oldTemplate.faq.type${templateType}.otherList.${getTxt(descriptorName)}${
                item.headline
              }`,
            );
            return exist ? (
              <FaqItem key={item.id} className={isOpen && 'is-open'}>
                <FaqHeadlineWrapper onClick={() => setIsOpen(!isOpen)}>
                  <FaqHeadline
                    dangerouslySetInnerHTML={{
                      __html: t(
                        `oldTemplate.faq.type${templateType}.otherList.${getTxt(descriptorName)}${
                          item.headline
                        }`,
                        {
                          descriptor_email: config.MAILER_SUPPORT_EMAIL.toLowerCase(),
                          descriptor: config.TRADE_NAME.toLowerCase(),
                          descriptor_domain: config.DESCRIPTOR_DOMAIN.toLowerCase(),
                        },
                      ),
                    }}
                  />
                  <ArrowIcon />
                </FaqHeadlineWrapper>
                <FaqTxt
                  dangerouslySetInnerHTML={{
                    __html: t(
                      `oldTemplate.faq.type${templateType}.otherList.${getTxt(descriptorName)}${
                        item.txt
                      }`,
                      {
                        descriptor_email: config.MAILER_SUPPORT_EMAIL.toLowerCase(),
                        descriptor: config.TRADE_NAME.toLowerCase(),
                        descriptor_domain: config.DESCRIPTOR_DOMAIN.toLowerCase(),
                      },
                    ),
                  }}
                />
              </FaqItem>
            ) : null;
          })}
        </SectionFaqList>
      </SectionContainer>
    </Section>
  );
};
export default OldTemplate;

import styled from 'styled-components';

export const SectionItem = styled.div`
  width: 100%;
  background: ${(props) => props.theme.topItemBgMobile};
  backdrop-filter: ${(props) => props.theme.topItemBgMobile};
  border-radius: 16px;
  padding: 15px;
  height: fit-content;

  &.oldTemplate {
    padding: 0;
  }

  @media (min-width: 991px) {
    background: ${(props) => props.theme.topItemBg};
    padding: 30px;

    &.oldTemplate {
      padding: 0;
    }
  }
`;

export const SectionItemTop = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  &.oldTemplate {
    gap: 5px;
    align-items: center;
  }

  @media (min-width: 991px) {
    flex-direction: column;
    align-items: flex-start;

    &.oldTemplate {
      flex-direction: row;
      align-items: flex-start;
      gap: 5px;
    }
  }
`;

export const SectionItemIcon = styled.div`
  width: 24px;
  height: 24px;
  color: ${(props) => props.theme.iconArrowColor};
  &#blue {
    color: #fff;
    rect {
      fill: #228ef3;
    }
  }

  &#green {
    color: #fff;
    rect {
      fill: #16c2ad;
    }
  }

  &.oldTemplate {
    width: 16px;
    height: 16px;
    min-width: 16px;

    svg {
      width: 16px;
    }
  }

  @media (min-width: 991px) {
    &.oldTemplate {
      width: 20px;
      height: 20px;
      min-width: 20px;

      svg {
        width: 20px;
      }
    }
  }
`;

export const SectionItemHeadline = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => props.theme.topItemTxtMobile};

  &.oldTemplate {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${(props) => (props.useDarkTxt ? '#2b313b' : props.theme.topItemTxtMobile)};
  }

  @media (min-width: 991px) {
    color: ${(props) => (props.useDarkTxt ? '#2b313b' : props.theme.topItemTxt)};

    &.oldTemplate {
      font-size: 18px;
    }
  }
`;

export const SectionItemTxt = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.topItemTxtMobile};
  margin-top: 8px;

  @media (min-width: 991px) {
    color: ${(props) => props.theme.topItemTxt};
  }
`;

import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Main from '@Pages/Main';
import Header from '@Components/Header';
import Footer from '@Components/Footer';
import PrivacyPolicy from '@Pages/PrivacyPolicy';
import ScrollToTop from '@Hooks/ScrollToTop';

const PublicRoutes = () => {
  return (
    <>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      <Footer />
    </>
  );
};

const RootRoutes = () => {
  return <PublicRoutes />;
};

export default RootRoutes;

import styled from 'styled-components';

export const FooterWrapper = styled.div`
  width: 100%;
  max-width: calc(670px + 30px);
  padding: 0 15px;
  margin: 0 auto;

  @media (min-width: 991px) {
    max-width: calc(1170px + 30px);
  }
`;

export const FooterTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (min-width: 991px) {
    gap: 66px;
    flex-direction: row;
  }
`;

export const FooterLogo = styled.p`
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => props.theme.textMainLighter};
  span {
    color: ${(props) => props.theme.accent};
  }
`;

export const FooterDisclaimer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const FooterDisclaimerHeadline = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.disclaimer};
`;

export const FooterDisclaimerText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => props.theme.footerTxt};
`;

export const FooterAddressText = styled.div`
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: ${(props) => props.theme.footerAddressTxt};
  width: 100%;
  max-width: 410px;
  margin: 20px auto 0;
`;

export const FooterDisclaimerButton = styled.button`
  display: inline-block;
  text-align: right;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => props.customColor ?? props.theme.accent};
`;

export const FooterBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  gap: 30px;

  @media (min-width: 991px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const FooterCopy = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => props.theme.footerTxt};
  text-align: center;

  @media (min-width: 991px) {
    text-align: left;
  }

  .lasta & {
    span {
      text-transform: lowercase;
    }
  }

  .oldTemplate & {
    color: #8c99a0;
  }
`;

export const FooterCopyContainer = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => props.theme.footerTxt};
  text-align: center;
  color: #8c99a0;
  margin: 60px auto 0;
`;

export const FooterInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: ${(props) => props.theme.footerTxt};

  a {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: ${(props) => props.theme.footerTxt};
  }
`;

export const FooterSection = styled.div`
  padding: 50px 0;
  background: ${(props) => props.theme.backgroundColor};

  @media (min-width: 991px) {
    padding: 100px 0 50px;
  }

  &.lasta {
    padding: 45px 0 30px;
    background: ${({ theme, templateVariant }) =>
      templateVariant && theme.footerBg[templateVariant]};

    @media (min-width: 991px) {
      padding: 100px 0 50px;
    }

    ${FooterDisclaimerHeadline}, ${FooterInfo}, ${FooterCopy}, ${FooterDisclaimerText} {
      color: ${({ theme, templateVariant }) =>
        templateVariant && theme.footerTxtColor[templateVariant]};
    }

    ${FooterInfo} {
      flex-direction: column;

      @media (min-width: 991px) {
        flex-direction: row;
      }

      a {
        color: ${({ theme, templateVariant }) =>
          templateVariant && theme.footerTxtColor[templateVariant]};
      }
    }
  }

  &.oldTemplate {
    padding: 32px 0 40px;

    ${FooterWrapper} {
      max-width: 992px;
      padding: 0 16px;
    }

    ${FooterInfo} {
      flex-direction: column;
      gap: 0;
    }

    ${FooterDisclaimer} {
      margin-top: 32px;
    }

    ${FooterDisclaimerHeadline} {
      color: #35363f;
      font-size: 15px;
      font-weight: 300;
      margin-bottom: 15px;
    }

    ${FooterDisclaimerText} {
      font-size: 12px;
      color: #848a93;
    }

    ${FooterDisclaimerButton} {
      color: #9cb5cc;
    }

    .privacy {
      color: #2b313b;
      font-size: 20px;
      display: inline-block;
      margin-bottom: 40px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const FooterContacts = styled.div`
  display: flex;
  gap: 5px;
`;

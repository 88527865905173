import React from 'react';
import PropTypes from 'prop-types';
import { FormBtn, PrimaryBtn } from './styled';

export const FormButton = ({ type = 'submit', children, oldTemplate = false, ...props }) => {
  return (
    <FormBtn typy={type} {...props} className={oldTemplate && 'oldTemplate'}>
      {children}
    </FormBtn>
  );
};

FormButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  type: PropTypes.string,
  oldTemplate: PropTypes.bool,
};

export const PrimaryButton = ({ children, ...props }) => {
  return <PrimaryBtn {...props}>{children}</PrimaryBtn>;
};

PrimaryButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

import React from 'react';
import MainScreenForm from '@Components/MainScreenForm';
import { useTranslation } from 'react-i18next';
import config from '@/config.json';
import { useMediaQuery } from 'react-responsive';
import {
  LastaImage,
  LastaListIcon,
  LastaListItem,
  LastaListWrapper,
  LastaSection,
  LastaSectionBox,
  LastaSectionCaption,
  LastaSectionHeadline,
  LastaSectionWrapper,
} from './styled';
import list from './listOptions.json';

const LastaTemplate = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-device-width: 991px)',
  });
  const template = config.TEMPLATE;
  const imageUrl = `/images/main-screen/lastaTemplate/heroSection/${config.THEME}/lasta-${config.VARIANT}`;
  const variant = `variant_${config.VARIANT}`;
  const theme = `${config.THEME}`;

  return (
    <LastaSection id="main" {...(config.VARIANT && { templateVariant: variant })}>
      <LastaSectionWrapper>
        <LastaSectionBox>
          <LastaSectionCaption>
            <LastaSectionHeadline
              variant={template}
              {...(config.VARIANT && { templateVariant: variant })}
            >
              {t(`mainSection.headline`)}
            </LastaSectionHeadline>
            <LastaListWrapper>
              {list?.map((item) => (
                <LastaListItem
                  key={item.id}
                  variant={template}
                  {...(config.VARIANT && { templateVariant: variant })}
                  {...(theme === 'dark' &&
                    (variant === 'variant_2' || variant === 'variant_3') && {
                      className: 'invert',
                    })}
                >
                  <LastaListIcon src={`/images/icons/${item.icon}.svg`} alt="" />
                  {t(`lasta.mainSectionList.${item.text}`)}
                </LastaListItem>
              ))}
            </LastaListWrapper>
            {isMobile && <MainScreenForm />}
            <LastaImage
              srcSet={`${imageUrl}@2x.png 2x`}
              src={`${imageUrl}.png`}
              width="456"
              height="428"
            >
              <source
                media="(max-width:990px)"
                srcSet={`${imageUrl}-mobile.png, ${imageUrl}-mobile@2x.png 2x`}
              />
              <source media="(min-width:991px)" srcSet={`${imageUrl}.png, ${imageUrl}@2x.png 2x`} />
              {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
              <img alt="Hero image" />
            </LastaImage>
          </LastaSectionCaption>

          {!isMobile && <MainScreenForm />}
        </LastaSectionBox>
      </LastaSectionWrapper>
    </LastaSection>
  );
};

export default LastaTemplate;

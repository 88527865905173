import React, { useEffect, useState } from 'react';
import listMenu from '@Components/Menu/listMenu.json';
import listMenuOld from '@Components/Menu/listMenuOld';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import config from '@/config.json';
import { MobileNavWrapper, MobileNav, MobileBurger } from './styled';

const MobileMenu = ({ isMenuOpen, setIsMenuOpen, setIsLangOpen }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const template = config.TEMPLATE;
  const variant = `variant_${config.VARIANT}`;
  const oldTemplate = ['oldTemplate'].includes(template);

  const changeHandle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    isMenuOpen && setIsLangOpen(false);
  }, [isMenuOpen]);

  const scrollTo = (event, block) => {
    if (location.pathname === '/') {
      event.preventDefault();

      const section = document.querySelector(`${block.replace('/', '')}`);

      if (section) {
        setIsMenuOpen(false);
        window.scrollTo({
          top: section.offsetTop - 100,
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <MobileNavWrapper
      className={`${isMenuOpen ? 'is-open' : ''} ${template}`}
      {...(config.VARIANT && { templateVariant: variant })}
    >
      <MobileBurger
        onClick={changeHandle}
        className={template}
        {...(config.VARIANT && { templateVariant: variant })}
      >
        <i />
        <i />
        <i />
      </MobileBurger>
      <MobileNav className={template} {...(config.VARIANT && { templateVariant: variant })}>
        {(oldTemplate ? listMenuOld(config.MENU_TYPE) : listMenu).map((elem) => (
          <NavLink
            key={elem.name}
            to={location.pathname === '/' ? '' : `${elem.link}`}
            onClick={(event) => scrollTo(event, elem.link)}
          >
            {oldTemplate ? t(`oldTemplate.header.${elem.name}`) : t(`header.${elem.name}`)}
          </NavLink>
        ))}
        {!oldTemplate ? (
          <a href={`tel:${config.DESCRIPTOR_PHONE}`}>{config.DESCRIPTOR_PHONE}</a>
        ) : null}
      </MobileNav>
    </MobileNavWrapper>
  );
};

MobileMenu.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  setIsMenuOpen: PropTypes.func.isRequired,
  setIsLangOpen: PropTypes.func.isRequired,
};

export default MobileMenu;

import React from 'react';
import * as PropTypes from 'prop-types';
import { CheckIcon } from '@Components/UI/Icons';
import { useTranslation } from 'react-i18next';
import config from '@/config.json';
import {
  SectionItem,
  SectionItemIcon,
  SectionItemTxt,
  SectionItemHeadline,
  SectionItemTop,
} from './styled';

const MainScreenElement = ({ headline, text = null }) => {
  const { t } = useTranslation();
  const template = config.TEMPLATE;
  const brandName = config.DESCRIPTOR_NAME;
  const oldTemplate = ['oldTemplate'].includes(template);
  const useDarkTxt = config?.DESCRIPTOR_TAG === 'appups.info';

  const getCustomCheck = (() => {
    const colorMapping = {
      'PROTECT247.PRO': 'green',
      'PROTECT247.LIVE': 'green',
      'PROTECT247.SHOP': 'green',
      'PROTECT247.MOBI': 'green',
      'KIDGY.MOBI': 'green',
      'KIDGY.ONLINE': 'green',
      'KIDGY.SERVICES': 'green',
      'KIDGY.SHOP': 'green',
      'KIDGY.TOOLS': 'green',
      'SPOTTRACK.MOBI': 'green',
      'SPOTTRACK.ONLINE': 'green',
      'SPOTTRACK.PRO': 'green',
      'SPOTTRACK.INFO': 'green',
      'SPOTTRACK.SHOP': 'green',
      'SEARQLE.PRO': 'green',
      'SEARQLE.INFO': 'green',
      'SEARQLE.CLICK': 'green',
      'SEARQLE.SITE': 'green',
      'PROTECT247.LINK': 'blue',
      'SEARQLE.STORE': 'blue',
      'DAILY-MDH.PRO': 'green',
      'PLANMD-TP.PRO': 'green',
      'TOPPLAN.PRO': 'green',
      'TPLANMDT.PRO': 'green',
      'YOUMDPLN.PRO': 'green',
      'Y-MEDITN.PRO': 'green',
      'YRMDTN.PRO': 'green',
      'DLY-HLPR.PRO': 'green',
      'DAYHPR.PRO': 'green',
    };
    return colorMapping[brandName] || '';
  })();

  return (
    <SectionItem className={oldTemplate && template}>
      <SectionItemTop className={oldTemplate && template}>
        <SectionItemIcon id={getCustomCheck} className={oldTemplate && template}>
          <CheckIcon template={template} />
        </SectionItemIcon>
        <SectionItemHeadline
          className={oldTemplate && template}
          dangerouslySetInnerHTML={{
            __html: t(`${headline}`),
          }}
          useDarkTxt={useDarkTxt}
        />
      </SectionItemTop>
      {text !== null && <SectionItemTxt>{t(`${text}`)}</SectionItemTxt>}
    </SectionItem>
  );
};

MainScreenElement.propTypes = {
  headline: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default MainScreenElement;

import React from 'react';
import ContentSection from '@Components/ContentSection';
import config from '@/config.json';

const PrivacyPolicy = () => {
  const template = config?.TEMPLATE;
  const path = config?.POLICY_DATE ? 'privacy.contentWithDate' : 'privacy.content';

  switch (template) {
    case 'lasta':
      return <ContentSection text="lasta.privacy.content" />;
    default:
      return <ContentSection text={path} />;
  }
};

export default PrivacyPolicy;

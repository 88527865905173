import React from 'react';
import { useTranslation } from 'react-i18next';

import config from '@/config.json';
import {
  GridElement,
  GridElementHeadline,
  GridElementItem,
  GridElementList,
  GridElementSubHeadline,
  GridElementSubHeadlineBottom,
  GridElementText,
  Section,
  SectionBox,
  SectionWrapper,
} from './styled';

const OldTemplate = () => {
  const { t, i18n } = useTranslation();
  const templateType = config.WHO_SECTION_TYPE;
  const currentLanguage = i18n.language;

  return (
    <Section id="about">
      <SectionWrapper>
        {(() => {
          if (templateType === '2' && currentLanguage === 'en') {
            return (
              <SectionBox>
                <GridElement>
                  <GridElementText
                    className="oldTemplate"
                    dangerouslySetInnerHTML={{
                      __html: t('oldTemplate.whoSection.type2.txt1', {
                        descriptor: config.TRADE_NAME,
                        descriptor_domain: config.DESCRIPTOR_DOMAIN,
                      }),
                    }}
                  />
                  <GridElementText
                    className="oldTemplate"
                    dangerouslySetInnerHTML={{
                      __html: t('oldTemplate.whoSection.type2.txt2', {
                        descriptor: config.TRADE_NAME,
                        descriptor_domain: config.DESCRIPTOR_DOMAIN,
                      }),
                    }}
                  />
                  <GridElementText
                    className="oldTemplate"
                    dangerouslySetInnerHTML={{
                      __html: t('oldTemplate.whoSection.type2.txt3', {
                        descriptor: config.TRADE_NAME,
                        descriptor_domain: config.DESCRIPTOR_DOMAIN,
                      }),
                    }}
                  />
                  <GridElementText
                    className="oldTemplate"
                    dangerouslySetInnerHTML={{
                      __html: t('oldTemplate.whoSection.type2.txt4', {
                        descriptor: config.TRADE_NAME,
                        descriptor_domain: config.DESCRIPTOR_DOMAIN,
                      }),
                    }}
                  />
                </GridElement>
              </SectionBox>
            );
          }
          if (templateType === '3' && currentLanguage === 'en') {
            return (
              <SectionBox>
                <GridElement>
                  <GridElementText
                    className="oldTemplate"
                    dangerouslySetInnerHTML={{
                      __html: t('oldTemplate.whoSection.type3.txt1', {
                        descriptor: config.TRADE_NAME,
                        descriptor_domain: config.DESCRIPTOR_DOMAIN,
                      }),
                    }}
                  />
                  <GridElementText
                    className="oldTemplate"
                    dangerouslySetInnerHTML={{
                      __html: t('oldTemplate.whoSection.type3.txt2', {
                        descriptor: config.TRADE_NAME,
                        descriptor_domain: config.DESCRIPTOR_DOMAIN,
                      }),
                    }}
                  />
                  <GridElementText
                    className="oldTemplate"
                    dangerouslySetInnerHTML={{
                      __html: t('oldTemplate.whoSection.type3.txt3', {
                        descriptor: config.TRADE_NAME,
                        descriptor_domain: config.DESCRIPTOR_DOMAIN,
                      }),
                    }}
                  />
                </GridElement>
              </SectionBox>
            );
          }
          return (
            <SectionBox>
              <GridElement>
                <GridElementHeadline>
                  {t(`oldTemplate.whoSection.type1.headline`)}
                </GridElementHeadline>
                <GridElementText
                  dangerouslySetInnerHTML={{
                    __html: [
                      'oldTemplate.whoSection.type1.txt1',
                      'oldTemplate.whoSection.type1.txt2',
                      'oldTemplate.whoSection.type1.txt3',
                    ]
                      .map((txt) =>
                        t(txt, {
                          descriptor: config.TRADE_NAME.toLowerCase(),
                          descriptor_domain: config.DESCRIPTOR_DOMAIN.toLowerCase(),
                        }),
                      )
                      .join(' '),
                  }}
                />
              </GridElement>
              <GridElement>
                <GridElementSubHeadline>
                  {t(`oldTemplate.whoSection.type1.subHeadline1`)}
                </GridElementSubHeadline>
                <GridElementList>
                  <GridElementItem>{t(`oldTemplate.whoSection.type1.list1`)}</GridElementItem>
                  <GridElementItem>{t(`oldTemplate.whoSection.type1.list2`)}</GridElementItem>
                  <GridElementItem>{t(`oldTemplate.whoSection.type1.list3`)}</GridElementItem>
                  <GridElementItem>{t(`oldTemplate.whoSection.type1.list4`)}</GridElementItem>
                  <GridElementItem>{t(`oldTemplate.whoSection.type1.list5`)}</GridElementItem>
                </GridElementList>
                <GridElementText>
                  {t(`oldTemplate.whoSection.type1.txt4`, {
                    descriptor: config.TRADE_NAME.toLowerCase(),
                    descriptor_domain: config.DESCRIPTOR_DOMAIN.toLowerCase(),
                  })}
                </GridElementText>
              </GridElement>
              <GridElement>
                <GridElementSubHeadline>
                  {t(`oldTemplate.whoSection.type1.subHeadline2`, {
                    descriptor: config.TRADE_NAME,
                    descriptor_domain: config.DESCRIPTOR_DOMAIN,
                  })}
                </GridElementSubHeadline>
                <GridElementText>
                  {t(`oldTemplate.whoSection.type1.txt5`, {
                    descriptor: config.TRADE_NAME.toLowerCase(),
                    descriptor_domain: config.DESCRIPTOR_DOMAIN.toLowerCase(),
                  })}
                </GridElementText>
              </GridElement>
              <GridElement>
                <GridElementSubHeadlineBottom>
                  {t(`oldTemplate.whoSection.type1.subHeadline3`)}
                </GridElementSubHeadlineBottom>
                <GridElementText>{t(`oldTemplate.whoSection.type1.txt6`)}</GridElementText>
              </GridElement>
            </SectionBox>
          );
        })()}
      </SectionWrapper>
    </Section>
  );
};

export default OldTemplate;

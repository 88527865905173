import React from 'react';
import config from '@/config.json';
import { Helmet } from 'react-helmet';

const HelmetComponent = () => {
  return (
    <Helmet>
      <title>{config.DESCRIPTOR_NAME}</title>
      <meta name="description" content={config.DESCRIPTOR_NAME} />
    </Helmet>
  );
};

export default HelmetComponent;

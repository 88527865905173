import React from 'react';
import config from '@/config.json';
import MainScreenForm from '@Components/MainScreenForm';
import MainScreenList from '@Components/MainScreenList';
import { useTranslation } from 'react-i18next';
import { Section, SectionBox, SectionHeadline, SectionCaption, SectionWrapper } from './styled';

const OldTemplate = () => {
  const { t, i18n } = useTranslation();
  const useDarkTxt = config?.DESCRIPTOR_TAG === 'appups.info';

  const getHeaderType = () => {
    let prefix = `${config.TRADE_NAME}.${config.DESCRIPTOR_DOMAIN}`;
    let suffix = t(`oldTemplate.mainSection.headline`);

    if (
      i18n.language === 'de' &&
      [
        'profbill.app',
        'paysoft.app',
        'parent-msp.app',
        'ctrlsoft.app',
        'bill-msp.app',
        'kidctrl.app',
        'my-kid.app',
        'softbill.app',
        'softctrl.app',
        'mpotect.app',
      ].includes(config?.DESCRIPTOR_TAG.toLowerCase())
    ) {
      suffix = t(`oldTemplate.mainSection.headline_type2`);
    }

    if (
      i18n.language === 'de' &&
      ['secure-msp.app'].includes(config?.DESCRIPTOR_TAG.toLowerCase())
    ) {
      suffix = t(`oldTemplate.mainSection.headline_type4`);
    }

    if (
      i18n.language === 'de' &&
      ['onlinecontrol.app'].includes(config?.DESCRIPTOR_TAG.toLowerCase())
    ) {
      suffix = t(`oldTemplate.mainSection.headline_type3`);
    }

    if (
      i18n.language === 'fr' &&
      ['onlinecontrol.app'].includes(config?.DESCRIPTOR_TAG.toLowerCase())
    ) {
      suffix = t(`oldTemplate.mainSection.headline_type2`);
    }

    if (
      i18n.language === 'fr' &&
      [
        'my-kid.app',
        'profbill.app',
        'parent-msp.app',
        'paysoft.app',
        'softbill.app',
        'softctrl.app',
        'mpotect.app',
      ].includes(config?.DESCRIPTOR_TAG.toLowerCase())
    ) {
      prefix = t(`oldTemplate.mainSection.headline_type3`);
      suffix = `${config.TRADE_NAME}.${config.DESCRIPTOR_DOMAIN}`;
    }

    return `${prefix} ${suffix}`;
  };

  const getBgPosition = (type) => {
    if (
      [
        'supportapponline.com',
        'supportapp24.com',
        'pcsupportapp.com',
        'quicksupport.pro',
        'qualitysupport.live',
        'topsupportapp.com',
        'websupportapp.com',
        'wesupportapp.com',
      ].includes(type)
    ) {
      return 'top';
    }

    return 'center';
  };

  return (
    <Section
      id="main"
      bgImage={`/images/main-screen/oldTemplate/${config.TRADE_NAME.toLowerCase()}${config.DESCRIPTOR_DOMAIN.toLowerCase()}`}
      bgPosition={getBgPosition(config?.DESCRIPTOR_TAG.toLowerCase())}
      className="oldTemplate"
    >
      <SectionWrapper>
        <SectionBox>
          <SectionCaption>
            <SectionHeadline useDarkTxt={useDarkTxt}>{getHeaderType()}</SectionHeadline>
            <MainScreenList />
          </SectionCaption>

          <MainScreenForm />
        </SectionBox>
      </SectionWrapper>
    </Section>
  );
};
export default OldTemplate;

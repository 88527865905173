import styled from 'styled-components';

export const TextAreaField = styled.textarea`
  position: relative;
  width: 100%;
  height: 110px;
  padding: 10px 16px 10px 12px;
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.textareaPlaceholderBorderColor};
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6b7295;

  &::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${(props) => props.theme.textareaPlaceholderColor};
  }

  &:focus {
    border: 1px solid #36b289;
  }

  .oldTemplate & {
    padding-left: 18px;
    padding-right: 18px;

    &:focus {
      border: 1px solid #afb6d7;
    }
  }
`;

export const TextAreaControl = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

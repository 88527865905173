import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import config from '@/config.json';
import listMenu from './listMenu.json';
import listMenuOld from './listMenuOld';

const Nav = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const template = config.TEMPLATE;
  const oldTemplate = ['oldTemplate'].includes(template);

  const scrollTo = (event, block) => {
    if (location.pathname === '/') {
      event.preventDefault();

      const section = document.querySelector(`${block.replace('/', '')}`);

      if (section) {
        window.scrollTo({
          top: section.offsetTop - 100,
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <>
      {(oldTemplate ? listMenuOld(config.MENU_TYPE) : listMenu).map((elem) => (
        <NavLink
          key={elem.name}
          to={location.pathname === '/' ? '' : `${elem.link}`}
          onClick={(event) => scrollTo(event, elem.link)}
        >
          {oldTemplate ? t(`oldTemplate.header.${elem.name}`) : t(`header.${elem.name}`)}
        </NavLink>
      ))}
    </>
  );
};

export default Nav;

import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  padding: 30px 0 0;
  background: #f9f9fa;

  @media (min-width: 991px) {
    padding: 30px 0 0;
  }
`;

export const SectionContainer = styled.div`
  width: 100%;
  max-width: calc(680px + 30px);
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 991px) {
    max-width: calc(770px + 30px);
  }

  &.bottom {
    margin-top: 68px;
  }
`;

export const SectionHeadline = styled.h2`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: ${(props) => props.theme.billingHeadline};

  @media (min-width: 991px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const SectionFaqList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 48px;
  margin-bottom: 48px;
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
`;

export const FaqHeadline = styled.h4`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  user-select: none;

  span {
    text-transform: uppercase;
  }
`;

export const FaqHeadlineWrapper = styled.div`
  padding-right: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;

  svg {
    position: absolute;
    top: 10px;
    right: 0;
    width: 24px;
    height: 24px;
    transform: translateY(-50%) rotate(180deg);
    transition: transform 0.3s ease-in-out;
    color: #2b313b;
  }

  @media (min-width: 991px) {
    padding-right: 40px;
  }
`;

export const FaqTxt = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 10px 0;
  margin-top: 12px;
  display: none;
  border-top: 1px solid #dcdee2;
  height: 0;
  transition: height 1s linear 0.3s;

  span {
    text-transform: uppercase;
  }

  a {
    white-space: nowrap;
    font-weight: 700;
    color: #2b313b;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: #7cb937;
    }
  }
`;

export const FaqItem = styled.div`
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;

  @media (min-width: 991px) {
    padding: 26px 32px 30px;
  }

  ${FaqTxt}, ${FaqHeadline} {
    color: ${(props) => props.theme.billingHeadline};
  }

  ${FaqHeadlineWrapper} {
    svg {
      color: rgba(43, 49, 59, 0.48);
    }
  }

  &.is-open {
    ${FaqTxt} {
      display: block;
      height: auto;
      transition: height 1s linear 0.3s;
    }

    ${FaqHeadlineWrapper} {
      svg {
        transform: translateY(-50%) rotate(0);
      }
    }
  }
`;

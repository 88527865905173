import React from 'react';

export const LastaLogo = () => {
  return (
    <svg
      width="102"
      height="30"
      viewBox="0 0 102 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.5098 5.38474H49.6116V7.16293L56.5522 13.9192C59.9917 17.2674 60.3374 22.5828 57.3592 26.3266C53.7518 30.8613 46.8506 31.2234 42.7633 27.0925L39.5866 23.882L42.8111 20.6666L41.6205 19.4633L40.2789 18.1271L37.0085 21.2955L37.0085 21.2956L31.8171 26.3492C27.3124 30.7342 20.009 30.7342 15.5044 26.3492C10.9998 21.9642 10.9998 14.8547 15.5044 10.4697C20.009 6.08465 27.3124 6.08465 31.8171 10.4697L34.2473 12.8354L31.0034 16.0295L28.5545 13.6456C25.8518 11.0146 21.4697 11.0146 18.7669 13.6456C16.0641 16.2766 16.0641 20.5423 18.7669 23.1733C21.4697 25.8043 25.8518 25.8043 28.5545 23.1733L36.7135 15.2361L46.5098 5.38474ZM41.6207 19.4633L46.0864 23.9767C48.2216 26.1346 51.8268 25.9455 53.7112 23.5766C55.267 21.6209 55.0864 18.8442 53.2896 17.0951L47.3144 11.2785L40.279 18.1271L41.6207 19.4633ZM36.9787 25.3286L33.7323 28.562L35.1196 29.9437H41.6125L36.9787 25.3286Z"
        fill="currentColor"
      />
      <path
        d="M9.42003 24.9632H12.1261V29.4256H8.48845C6.74357 29.4256 5.23529 29.0869 3.96361 28.4094C2.69192 27.732 1.71597 26.7599 1.03577 25.4934C0.355565 24.1973 0.015462 22.6804 0.015462 20.9426V9.76436H0L0.015462 1.90735e-06H4.89519V9.76436H4.93955V20.5449C4.93955 21.9588 5.32402 23.0486 6.09294 23.8144C6.86187 24.5803 7.9709 24.9632 9.42003 24.9632Z"
        fill="currentColor"
      />
      <path
        d="M71.1872 24.9632H73.8933V29.4256H70.2557C68.5108 29.4256 67.0025 29.0869 65.7308 28.4094C64.4591 27.732 63.4832 26.7599 62.803 25.4934C62.1228 24.1973 61.7827 22.6804 61.7827 20.9426V9.76436H57.4796V8.83653L65.8195 1.90735e-06H66.6624V5.56701H73.7602V9.76436H66.7068V20.5449C66.7068 21.9588 67.0912 23.0486 67.8602 23.8144C68.6291 24.5803 69.7381 24.9632 71.1872 24.9632Z"
        fill="currentColor"
      />
      <path
        d="M97.1517 5.56701H100.967V29.4256H96.9743L96.4863 26.5096C94.1795 28.8365 91.296 30 87.8359 30C84.2574 30 81.2704 28.8071 78.8749 26.4212C76.509 24.0353 75.326 21.0604 75.326 17.4963C75.326 13.9028 76.509 10.9278 78.8749 8.57143C81.2704 6.18557 84.2574 4.99264 87.8359 4.99264C91.3552 4.99264 94.2682 6.17084 96.575 8.52725L97.1517 5.56701ZM82.5569 23.1075C84.0652 24.5803 85.9431 25.3166 88.1907 25.3166C90.4679 25.3166 92.3311 24.5803 93.7803 23.1075C95.259 21.6053 95.9983 19.7349 95.9983 17.4963C95.9983 15.2283 95.259 13.3579 93.7803 11.8851C92.3311 10.4124 90.4679 9.676 88.1907 9.676C86.712 9.676 85.3664 10.0147 84.1539 10.6922C82.9709 11.3697 82.0393 12.3122 81.3591 13.5199C80.6789 14.6981 80.3388 16.0236 80.3388 17.4963C80.3388 19.7349 81.0782 21.6053 82.5569 23.1075Z"
        fill="currentColor"
      />
      <path
        d="M71.1872 24.9632H73.8933V29.4256H70.2557C68.5108 29.4256 67.0025 29.0869 65.7308 28.4094C64.4591 27.732 63.4832 26.7599 62.803 25.4934C62.1228 24.1973 61.7827 22.6804 61.7827 20.9426V9.76436H57.4796V8.83653L65.8195 1.90735e-06H66.6624V5.56701H73.7602V9.76436H66.7068V20.5449C66.7068 21.9588 67.0912 23.0486 67.8602 23.8144C68.6291 24.5803 69.7381 24.9632 71.1872 24.9632Z"
        fill="currentColor"
      />
    </svg>
  );
};

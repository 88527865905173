import React from 'react';
import LastaTemplate from '@Containers/Who/LastaTemplate';
import DefaultTemplate from '@Containers/Who/DefaultTemplate';
import PropTypes from 'prop-types';
import OldTemplate from '@Containers/Who/OldTemplate';

const WhoScreen = ({ variant = '' }) => {
  switch (variant) {
    case 'lasta':
      return <LastaTemplate />;
    case 'oldTemplate':
      return <OldTemplate />;
    default:
      return <DefaultTemplate />;
  }
};

export default WhoScreen;

WhoScreen.propTypes = {
  variant: PropTypes.string,
};

import styled from 'styled-components';

export const FormWrapper = styled.div`
  position: relative;
  width: 100%;
  background: ${(props) => props.theme.formBgMobile};
  border-radius: ${(props) => props.theme.formRadius};
  padding: 20px 15px 30px;
  order: 2;

  &.lasta {
    margin-top: 30px;
    padding: 20px 16px;
    background: ${({ theme, templateVariant }) =>
      templateVariant && theme.formBgColor[templateVariant]};

    @media (min-width: 991px) {
      margin-top: 0;
    }
  }

  @media (min-width: 991px) {
    padding: 50px;
    max-width: 570px;
    background: ${(props) => props.customBGColor ?? props.theme.formBg};

    &.lasta {
      padding: 40px;
      max-width: 555px;
    }
  }

  &.oldTemplate {
    border-radius: 0;
    background: ${(props) => props.theme.formBgMobile};
    border: 1px solid rgba(43, 49, 59, 0.32);
    padding: 16px;

    @media (min-width: 991px) {
      border-radius: 5px;
      padding: 25px;
      max-width: 485px;
    }
  }
`;

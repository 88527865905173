import React from 'react';
import { useTranslation } from 'react-i18next';

import config from '@/config.json';
import {
  NotesSection,
  NotesSectionCaption,
  NotesSectionCaptionHeadline,
  NotesSectionCaptionTxt,
  NotesSectionImage,
  NotesSectionImageBox,
  NotesSectionItem,
  OfferSection,
  OfferSectionBox,
  OfferSectionCaption,
  OfferSectionCaptionButton,
  OfferSectionCaptionTxt,
  OfferSectionHeadline,
  OfferSectionImage,
  OfferSectionPoint,
  OfferSectionPoints,
  WhatSection,
  WhatSectionBox,
  WhatSectionCaption,
  WhatSectionHeadline,
  WhatSectionImage,
  WhatSectionTxt,
  Wrapper,
} from './styled';
import list from './offerOptions.json';

const LastaTemplate = () => {
  const { t } = useTranslation();
  const template = config.TEMPLATE;
  const variant = `variant_${config.VARIANT}`;
  const whatImageUrl = `/images/main-screen/lastaTemplate/whatSection/what`;
  const offerImageUrl = `/images/main-screen/lastaTemplate/whatSection/${config.THEME}/${config.VARIANT}-offer`;
  const notesManImageUrl = `/images/main-screen/lastaTemplate/whatSection/${config.THEME}/${config.VARIANT}-man`;
  const notesCollageImageUrl = `/images/main-screen/lastaTemplate/whatSection/${config.THEME}/${config.VARIANT}-collage`;

  return (
    <>
      <WhatSection
        id="about"
        variant={template}
        {...(config.VARIANT && { templateVariant: variant })}
      >
        <Wrapper>
          <WhatSectionBox>
            <WhatSectionCaption>
              <WhatSectionHeadline>{t(`lasta.what.headline`)}</WhatSectionHeadline>
              <WhatSectionTxt
                variant={template}
                {...(config.VARIANT && { templateVariant: variant })}
                dangerouslySetInnerHTML={{
                  __html: t(`lasta.what.txt`, {
                    descriptor_email: config.MAILER_SUPPORT_EMAIL.toLowerCase(),
                    descriptor: config.TRADE_NAME.toLowerCase(),
                    descriptor_domain: config.DESCRIPTOR_DOMAIN.toLowerCase(),
                  }),
                }}
              />
            </WhatSectionCaption>
            <WhatSectionImage
              srcSet={`${whatImageUrl}@2x.png 2x`}
              src={`${whatImageUrl}.png`}
              width="570"
              height="430"
            >
              <source
                media="(max-width:990px)"
                srcSet={`${whatImageUrl}-mobile.png, ${whatImageUrl}-mobile@2x.png 2x`}
              />
              <source
                media="(min-width:991px)"
                srcSet={`${whatImageUrl}.png, ${whatImageUrl}@2x.png 2x`}
              />
              {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
              <img alt="What we are image" />
            </WhatSectionImage>
          </WhatSectionBox>
        </Wrapper>
      </WhatSection>
      <OfferSection variant={template} {...(config.VARIANT && { templateVariant: variant })}>
        <Wrapper>
          <OfferSectionHeadline>{t(`lasta.offer.headline`)}</OfferSectionHeadline>
          <OfferSectionBox>
            <OfferSectionPoints>
              {list?.map((item) => (
                <OfferSectionPoint key={item.id}>
                  <span>{`0${item.id}`}</span>
                  {t(`lasta.offer.offers.${item.txt}`)}
                </OfferSectionPoint>
              ))}
            </OfferSectionPoints>
            <OfferSectionImage
              srcSet={`${offerImageUrl}@2x.png 2x`}
              src={`${offerImageUrl}.png`}
              width="370"
              height="396"
            >
              <source
                media="(max-width:990px)"
                srcSet={`${offerImageUrl}-mobile.png, ${offerImageUrl}-mobile@2x.png 2x`}
              />
              <source
                media="(min-width:991px)"
                srcSet={`${offerImageUrl}.png, ${offerImageUrl}@2x.png 2x`}
              />
              {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
              <img alt="What we are image" />
            </OfferSectionImage>
            <OfferSectionCaption>
              <OfferSectionCaptionTxt
                dangerouslySetInnerHTML={{
                  __html: t(`lasta.offer.offersTxt`, {
                    descriptor_email: config.MAILER_SUPPORT_EMAIL.toLowerCase(),
                    descriptor: config.TRADE_NAME.toLowerCase(),
                    descriptor_domain: config.DESCRIPTOR_DOMAIN.toLowerCase(),
                  }),
                }}
              />
              <OfferSectionCaptionButton href="/#main">
                {t(`lasta.offer.offersBtn`)}
              </OfferSectionCaptionButton>
            </OfferSectionCaption>
          </OfferSectionBox>
        </Wrapper>
      </OfferSection>
      <NotesSection variant={template} {...(config.VARIANT && { templateVariant: variant })}>
        <Wrapper>
          <NotesSectionItem>
            <NotesSectionImageBox>
              <NotesSectionImage
                srcSet={`${notesManImageUrl}@2x.png 2x`}
                src={`${notesManImageUrl}.png`}
                width="370"
                height="396"
              >
                <source
                  media="(max-width:990px)"
                  srcSet={`${notesManImageUrl}-mobile.png, ${notesManImageUrl}-mobile@2x.png 2x`}
                />
                <source
                  media="(min-width:991px)"
                  srcSet={`${notesManImageUrl}.png, ${notesManImageUrl}@2x.png 2x`}
                />
                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                <img alt="What we are image" />
              </NotesSectionImage>
            </NotesSectionImageBox>
            <NotesSectionCaption>
              <NotesSectionCaptionHeadline
                dangerouslySetInnerHTML={{
                  __html: t(`lasta.notes.notesManHeadline`, {}),
                }}
              />
              <NotesSectionCaptionTxt
                dangerouslySetInnerHTML={{
                  __html: t(`lasta.notes.notesManTxt`, {
                    descriptor_email: config.MAILER_SUPPORT_EMAIL.toLowerCase(),
                    descriptor: config.TRADE_NAME.toLowerCase(),
                    descriptor_domain: config.DESCRIPTOR_DOMAIN.toLowerCase(),
                  }),
                }}
              />
            </NotesSectionCaption>
          </NotesSectionItem>
          <NotesSectionItem>
            <NotesSectionImageBox>
              <NotesSectionImage
                srcSet={`${notesCollageImageUrl}@2x.png 2x`}
                src={`${notesCollageImageUrl}.png`}
                width="370"
                height="396"
              >
                <source
                  media="(max-width:990px)"
                  srcSet={`${notesCollageImageUrl}-mobile.png, ${notesCollageImageUrl}-mobile@2x.png 2x`}
                />
                <source
                  media="(min-width:991px)"
                  srcSet={`${notesCollageImageUrl}.png, ${notesCollageImageUrl}@2x.png 2x`}
                />
                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                <img alt="What we are image" />
              </NotesSectionImage>
            </NotesSectionImageBox>
            <NotesSectionCaption>
              <NotesSectionCaptionHeadline
                dangerouslySetInnerHTML={{
                  __html: t(`lasta.notes.notesCollageHeadline`, {}),
                }}
              />
              <NotesSectionCaptionTxt
                dangerouslySetInnerHTML={{
                  __html: t(`lasta.notes.notesCollageTxt`, {
                    descriptor_email: config.MAILER_SUPPORT_EMAIL.toLowerCase(),
                    descriptor: config.TRADE_NAME.toLowerCase(),
                    descriptor_domain: config.DESCRIPTOR_DOMAIN.toLowerCase(),
                  }),
                }}
              />
            </NotesSectionCaption>
          </NotesSectionItem>
        </Wrapper>
      </NotesSection>
    </>
  );
};

export default LastaTemplate;

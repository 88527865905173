import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  padding: 50px 0;
  background: ${(props) => props.theme.backgroundColor};

  @media (min-width: 991px) {
    padding: 100px 0;
  }
`;

export const SectionWrapper = styled.div`
  width: 100%;
  max-width: calc(670px + 30px);
  padding: 0 15px;
  margin: 0 auto;

  @media (min-width: 991px) {
    max-width: calc(1170px + 30px);
  }
`;

export const SectionBox = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;

  @media (min-width: 991px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const GridElement = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const GridElementHeadline = styled.h2`
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: ${(props) => props.theme.whoHeadline};
`;

export const GridElementSubHeadline = styled.h4`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => props.theme.whoHeadline};
`;

export const GridElementText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.whoTxt};
`;

export const GridElementList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const GridElementItem = styled.li`
  position: relative;
  padding-left: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.whoTxt};

  &:before {
    position: absolute;
    width: 4px;
    height: 4px;
    top: 8px;
    left: 2px;
    border-radius: 50%;
    background: ${(props) => props.theme.whoTxt};
    content: '';
  }
`;

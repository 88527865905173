import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FlagIcon,
  LangBox,
  LangCurrent,
  LangCurrentBox,
  LangIconWrapper,
  LangItem,
  LangList,
} from '@Components/LangSwitcher/styled';
import { LangIcon } from '@Components/UI/Icons';
import * as PropTypes from 'prop-types';
import config from '@/config.json';
import { useMediaQuery } from 'react-responsive';

const LangSwitcher = ({ isLangOpen, setIsLangOpen, setIsMenuOpen }) => {
  const { i18n } = useTranslation();
  const template = config.TEMPLATE;
  const colorAccent = config?.COLOR_ACCENT;
  const oldTemplate = ['oldTemplate'].includes(template);

  const isMobile = useMediaQuery({
    query: '(max-device-width: 991px)',
  });

  const changeHandle = () => {
    setIsLangOpen(!isLangOpen);
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    isLangOpen && setIsMenuOpen(false);
  }, [isLangOpen]);

  const changeLanguageHandler = (lng) => {
    setIsLangOpen(false);
    i18n.changeLanguage(lng);
    localStorage.setItem('lang', lng);
  };

  const availableLanguages = {
    en: 'English',
    de: 'Deutsch',
    fr: 'Français',
    es: 'Español',
    pt: 'Português',
  };

  const getCountryFlag = (name) => <FlagIcon src={`/images/flags/${name}.svg`} alt="lang icon" />;

  const lists = Object.entries(availableLanguages).map((lang) => (
    <>
      {oldTemplate ? (
        <LangItem
          customColor={colorAccent}
          className="oldTemplate"
          onClick={() => changeLanguageHandler(lang[0])}
          key={lang[0]}
        >
          {oldTemplate && getCountryFlag(lang[0])}
          {lang[1]}
        </LangItem>
      ) : (
        <LangItem
          customColor={colorAccent}
          onClick={() => changeLanguageHandler(lang[0])}
          key={lang[0]}
        >
          {lang[1]}
        </LangItem>
      )}
    </>
  ));

  return (
    <>
      {oldTemplate ? (
        <LangBox
          onMouseEnter={() => (!isMobile ? setIsLangOpen(true) : null)}
          onMouseLeave={() => (!isMobile ? setIsLangOpen(false) : null)}
          onClick={() => (isMobile ? changeHandle() : null)}
          className={`oldTemplate ${isLangOpen ? 'is-open' : ''}`}
          customColor={colorAccent}
        >
          <LangCurrentBox className="oldTemplate">
            <LangCurrent>{getCountryFlag(i18n.language)}</LangCurrent>
          </LangCurrentBox>
          {!!lists.length && <LangList className="oldTemplate">{lists}</LangList>}
        </LangBox>
      ) : (
        <LangBox
          onClick={changeHandle}
          className={`${isLangOpen ? 'is-open' : ''}`}
          customColor={colorAccent}
        >
          <LangCurrentBox>
            <LangCurrent>{i18n.language}</LangCurrent>
            <LangIconWrapper>
              <LangIcon width="16px" />
            </LangIconWrapper>
          </LangCurrentBox>
          {!!lists.length && <LangList>{lists}</LangList>}
        </LangBox>
      )}
    </>
  );
};

LangSwitcher.propTypes = {
  isLangOpen: PropTypes.bool.isRequired,
  setIsLangOpen: PropTypes.func.isRequired,
  setIsMenuOpen: PropTypes.func.isRequired,
};

export default LangSwitcher;

import { createGlobalStyle } from 'styled-components';

import reset from './reset';

export default createGlobalStyle`
  ${reset}
  html {
    scroll-behavior: smooth;
  }

  body {
    font-family: ${(props) =>
      props.theme.font ?? 'Inter'}, Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 22px;
    overflow-x: hidden;
    width: 100%;
    background-color: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.textMain};
    font-weight: 300;
  }

  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    width: 100%;
    position: relative;
  }

  .react-datepicker-popper {
    z-index: 9;
  }
`;

import config from '@/config.json';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridElement,
  GridElementHeadline,
  Section,
  SectionBox,
  SectionWrapper,
  GridElementText,
  GridListTop,
  GridListItem,
} from './styled';

const OldTemplate = () => {
  const { t, i18n } = useTranslation();
  const generateList = (listOrder, countItems, className) => {
    const markupArr = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < countItems; i++) {
      markupArr.push(
        <GridListItem
          className={className}
          dangerouslySetInnerHTML={{
            __html: t(`oldTemplate.terms.type1.listItem${listOrder}-${i}`),
          }}
        />,
      );
    }

    return <ul>{markupArr}</ul>;
  };

  if (config.TERMS_SECTION_TYPE !== '1') {
    return null;
  }

  return (
    <Section id="terms">
      <SectionWrapper>
        {(() => {
          switch (config.TERMS_SECTION_TYPE) {
            case '1':
              return (
                <SectionBox>
                  <Section>
                    <GridElement>
                      <GridElementHeadline>
                        {t(`oldTemplate.terms.type1.txt1.1`)}
                      </GridElementHeadline>
                    </GridElement>
                    <GridElementText className="oldTemplate">
                      {t(`oldTemplate.terms.type1.txt1.2`)}
                    </GridElementText>
                    <GridElementText className="oldTemplate">
                      {t(`oldTemplate.terms.type1.txt1.3`)}
                    </GridElementText>
                  </Section>
                  <Section>
                    <GridElementHeadline>{t(`oldTemplate.terms.type1.txt2`)}</GridElementHeadline>
                    <GridListTop>{t(`oldTemplate.terms.type1.listItem1`)}</GridListTop>
                    {(() => generateList(1, 9))()}
                    <GridListTop>{t(`oldTemplate.terms.type1.listItem2`)}</GridListTop>
                    {(() => generateList(2, 3))()}
                    <GridListTop>{t(`oldTemplate.terms.type1.listItem3`)}</GridListTop>
                    {(() => generateList(3, 4))()}
                    {(() => generateList('3-3', 8, 'subItem'))()}
                    <GridListItem
                      dangerouslySetInnerHTML={{
                        __html: t('oldTemplate.terms.type1.listItem3-4'),
                      }}
                    />
                    {(() => generateList('3-4', 14, 'subItem'))()}
                    <GridListItem
                      dangerouslySetInnerHTML={{
                        __html: t('oldTemplate.terms.type1.listItem3-5'),
                      }}
                    />
                    <GridListItem
                      dangerouslySetInnerHTML={{
                        __html: t('oldTemplate.terms.type1.listItem3-6'),
                      }}
                    />
                    <GridListItem
                      dangerouslySetInnerHTML={{
                        __html: t('oldTemplate.terms.type1.listItem3-7'),
                      }}
                    />
                    {(() => generateList('3-7', 4, 'subItem'))()}
                    <GridListItem
                      dangerouslySetInnerHTML={{
                        __html: t('oldTemplate.terms.type1.listItem3-8'),
                      }}
                    />
                    <GridListItem
                      dangerouslySetInnerHTML={{
                        __html: t('oldTemplate.terms.type1.listItem3-9'),
                      }}
                    />
                    {(() => generateList('3-9', 5, 'subItem'))()}
                    <GridListTop>{t(`oldTemplate.terms.type1.listItem4`)}</GridListTop>
                    {(() => generateList('4', 9))()}
                    <GridListTop>{t(`oldTemplate.terms.type1.listItem5`)}</GridListTop>
                    <GridListItem
                      dangerouslySetInnerHTML={{
                        __html: t('oldTemplate.terms.type1.listItem5-1'),
                      }}
                    />
                    <GridListTop>{t(`oldTemplate.terms.type1.listItem6`)}</GridListTop>
                    {(() => generateList('6', 6))()}
                    <GridListTop>
                      {t(`oldTemplate.terms.type1.What data do we collect?`)}
                    </GridListTop>
                    <GridListItem
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'oldTemplate.terms.type1.The information we collect about you includes',
                        ),
                      }}
                    />
                    <GridListItem>{t(`oldTemplate.terms.type1.informationItem1`)}</GridListItem>
                    <GridListItem>{t(`oldTemplate.terms.type1.informationItem2`)}</GridListItem>
                    <GridListItem>
                      {t(
                        `oldTemplate.terms.type1.We will never collect confidential information about you without your written consent`,
                      )}
                    </GridListItem>
                    <GridListItem
                      dangerouslySetInnerHTML={{
                        __html: t('oldTemplate.terms.type1.txt3.1'),
                      }}
                    />
                    <GridListItem
                      dangerouslySetInnerHTML={{
                        __html: t('oldTemplate.terms.type1.txt3.2'),
                      }}
                    />
                    <GridListItem
                      dangerouslySetInnerHTML={{
                        __html: t('oldTemplate.terms.type1.txt3.3'),
                      }}
                    />
                    <GridListItem
                      dangerouslySetInnerHTML={{
                        __html: t('oldTemplate.terms.type1.txt4.1'),
                      }}
                    />
                    <GridListItem
                      dangerouslySetInnerHTML={{
                        __html: t('oldTemplate.terms.type1.txt4.2'),
                      }}
                    />
                    <GridListItem
                      dangerouslySetInnerHTML={{
                        __html: t('oldTemplate.terms.type1.txt5.1'),
                      }}
                    />
                    <GridListItem
                      dangerouslySetInnerHTML={{
                        __html: t('oldTemplate.terms.type1.txt5.2'),
                      }}
                    />
                  </Section>
                </SectionBox>
              );
            default:
              return '';
          }
        })()}
      </SectionWrapper>
    </Section>
  );
};

export default OldTemplate;

import React, { useState } from 'react';
import config from '@/config.json';
import {
  HeaderBox,
  HeaderCenter,
  HeaderContainer,
  HeaderDiv,
  HeaderNav,
  HeaderPhone,
  HeaderPhoneWrapper,
} from '@Components/Header/styled';
import Menu from '@Components/Menu';
import LangSwitcher from '@Components/LangSwitcher';
import { useMediaQuery } from 'react-responsive';
import MobileMenu from '@Components/MobileMenu';
import { NavLink } from 'react-router-dom';
import Logo from '@Components/Logo';
import { PhoneIcon } from '@Components/UI/Icons';

const Header = () => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 991px)',
  });
  const colorAccent = config?.COLOR_ACCENT;

  const [isLangOpen, setIsLangOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const template = config.TEMPLATE;
  const disableLang =
    config?.IS_DiSABLE_LANG !== undefined ? config.IS_DiSABLE_LANG : ['lasta'].includes(template);
  const oldTemplate = ['oldTemplate'].includes(template);
  const variant = `variant_${config.VARIANT}`;

  return (
    <HeaderDiv className={template} {...(config.VARIANT && { templateVariant: variant })}>
      <HeaderContainer className={template}>
        <HeaderBox>
          <NavLink to="/">
            <Logo variant={template} {...(config.VARIANT && { templateVariant: variant })} />
          </NavLink>
          {!oldTemplate ? (
            <>
              <HeaderCenter>
                <HeaderNav
                  customColor={colorAccent}
                  className={template}
                  {...(config.VARIANT && { templateVariant: variant })}
                >
                  {!isMobile ? (
                    <Menu />
                  ) : (
                    <MobileMenu
                      isMenuOpen={isMenuOpen}
                      setIsMenuOpen={setIsMenuOpen}
                      setIsLangOpen={setIsLangOpen}
                    />
                  )}
                  {!disableLang && (
                    <LangSwitcher
                      customColor={colorAccent}
                      isLangOpen={isLangOpen}
                      setIsLangOpen={setIsLangOpen}
                      setIsMenuOpen={setIsMenuOpen}
                    />
                  )}
                </HeaderNav>
              </HeaderCenter>
              {!isMobile && (
                <HeaderPhone
                  href={`tel:${config.DESCRIPTOR_PHONE}`}
                  className={template}
                  {...(config.VARIANT && { templateVariant: variant })}
                >
                  {config.DESCRIPTOR_PHONE}
                </HeaderPhone>
              )}
            </>
          ) : (
            <>
              <HeaderCenter className={template}>
                <HeaderNav className={template} langDisable={disableLang}>
                  {!isMobile ? (
                    <Menu />
                  ) : (
                    <>
                      <MobileMenu
                        isMenuOpen={isMenuOpen}
                        setIsMenuOpen={setIsMenuOpen}
                        setIsLangOpen={setIsLangOpen}
                      />
                      <HeaderPhone href={`tel:${config.DESCRIPTOR_PHONE}`} className={template}>
                        {config.DESCRIPTOR_PHONE}
                      </HeaderPhone>
                      <PhoneIcon width="10px" color="white" />
                    </>
                  )}
                  {!disableLang && (
                    <LangSwitcher
                      isLangOpen={isLangOpen}
                      setIsLangOpen={setIsLangOpen}
                      setIsMenuOpen={setIsMenuOpen}
                    />
                  )}
                </HeaderNav>
              </HeaderCenter>
              {!isMobile && (
                <HeaderPhoneWrapper>
                  <PhoneIcon width="10px" color="white" />
                  <HeaderPhone href={`tel:${config.DESCRIPTOR_PHONE}`} className={template}>
                    {config.DESCRIPTOR_PHONE}
                  </HeaderPhone>
                </HeaderPhoneWrapper>
              )}
            </>
          )}
        </HeaderBox>
      </HeaderContainer>
    </HeaderDiv>
  );
};

export default Header;

import styled from 'styled-components';

export const FormBox = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 30px 15px;

  &.lasta {
    gap: 15px 15px;

    @media (min-width: 991px) {
      gap: 20px 15px;
    }

    textarea {
      background: ${({ theme, templateVariant }) =>
        templateVariant && theme.inputsBg[templateVariant]};
      height: 80px;
      border-radius: 8px;
      color: ${({ theme, templateVariant }) =>
        templateVariant && theme.formInputColor[templateVariant]};
      border: 1px solid
        ${({ theme, templateVariant }) => templateVariant && theme.formBorderColor[templateVariant]};

      @media (min-width: 991px) {
        height: 94px;
      }

      &::placeholder {
        color: ${({ theme, templateVariant }) =>
          templateVariant && theme.placeholderColor[templateVariant]};
      }

      &:focus {
        border: 2px solid ${(props) => props.theme.accent};
      }
    }

    select {
      background: ${({ theme, templateVariant }) =>
        templateVariant && theme.inputsBg[templateVariant]};
      border-radius: 8px;
      color: ${({ theme, templateVariant }) =>
        templateVariant && theme.formInputColor[templateVariant]};
      border: 1px solid
        ${({ theme, templateVariant }) => templateVariant && theme.formBorderColor[templateVariant]};

      &:focus {
        border: 2px solid ${(props) => props.theme.accent};
      }
    }

    input {
      background: ${({ theme, templateVariant }) =>
        templateVariant && theme.inputsBg[templateVariant]};
      border-radius: 8px;
      color: ${({ theme, templateVariant }) =>
        templateVariant && theme.formInputColor[templateVariant]};
      border: 1px solid
        ${({ theme, templateVariant }) => templateVariant && theme.formBorderColor[templateVariant]};

      &::placeholder {
        color: ${({ theme, templateVariant }) =>
          templateVariant && theme.placeholderColor[templateVariant]};
      }

      &:focus {
        border: 2px solid ${(props) => props.theme.accent};
      }

      &:-webkit-autofill {
        box-shadow: 0 0 0 62.5rem
          ${({ theme, templateVariant }) => templateVariant && theme.inputsBg[templateVariant]}
          inset;
        -webkit-box-shadow: 0 0 0 62.5rem
          ${({ theme, templateVariant }) => templateVariant && theme.inputsBg[templateVariant]}
          inset;
        -webkit-text-fill-color: ${({ theme, templateVariant }) =>
          templateVariant && theme.formInputColor[templateVariant]};
        background-clip: content-box !important;
      }
    }

    label {
      font-weight: 400;
      font-size: 11px;
      line-height: 18px;
      color: ${({ theme, templateVariant }) =>
        templateVariant && theme.labelColor[templateVariant]};

      @media (min-width: 991px) {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
      }
    }

    .input__half:has(input[name='amount']),
    .input__half:has(.react-datepicker-wrapper) {
      width: calc(50% - 8px);
    }

    button:not([type='button']) {
      border-radius: 30px;
      height: 60px;
      background: ${({ theme, templateVariant }) =>
        templateVariant && theme.formBtnBg[templateVariant]};
    }
  }

  &.oldTemplate {
    gap: 10px;

    input {
      &::placeholder {
        color: rgb(120, 123, 128);
      }
    }

    .input__half:has(input[name='amount']),
    .input__half:has(.react-datepicker-wrapper) {
      width: calc(50% - 5px);
    }
  }

  &.customColorStyle {
    input,
    select,
    textarea {
      &:focus {
        border-color: ${(props) => props.customColor};
      }
    }

    label[for='policy'] {
      &:after {
        box-shadow: ${(props) => props.customColor} 0px 0px 4px;
        border-color: ${(props) => props.customColor};
      }
    }

    .policy-note {
      color: ${(props) => props.customColor};
    }

    button {
      background: ${(props) => props.customColor};
    }
  }
`;

export const FormStatus = styled.p`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgb(54, 179, 137);
  text-align: center;

  &.error {
    color: #ff6c63;
  }
`;

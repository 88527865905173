import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import config from '@/config.json';
import { NavLink, useLocation } from 'react-router-dom';
import Logo from '@Components/Logo';
import {
  FooterSection,
  FooterWrapper,
  FooterTop,
  FooterDisclaimer,
  FooterDisclaimerHeadline,
  FooterDisclaimerText,
  FooterDisclaimerButton,
  FooterCopy,
  FooterBottom,
  FooterInfo,
  FooterContacts,
  FooterAddressText,
  FooterCopyContainer,
} from './styled';

const Footer = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [disclaimerOpen, setDisclaimerOpen] = useState(false);
  const year = new Date().getFullYear();

  const template = config.TEMPLATE;
  const variant = `variant_${config.VARIANT}`;
  const oldTemplate = ['oldTemplate'].includes(template);
  const descriptorName = config?.DESCRIPTOR_TAG.toLowerCase();
  const colorAccent = config?.COLOR_ACCENT;
  const showPrivacyPolicyLink = !pathname.includes('privacy-policy');

  const handleClick = () => {
    setDisclaimerOpen(!disclaimerOpen);
  };

  return (
    <FooterSection className={template} {...(config.VARIANT && { templateVariant: variant })}>
      {!oldTemplate ? (
        <FooterWrapper>
          <FooterTop>
            <NavLink to="/">
              <Logo
                variant={template}
                {...(config.VARIANT && { templateVariant: variant })}
                isFooter
              />
            </NavLink>
            <FooterDisclaimer>
              <FooterDisclaimerHeadline>{t(`footer.disclaimer.headline`)}</FooterDisclaimerHeadline>
              {template === 'lasta' ? (
                <FooterDisclaimerText
                  dangerouslySetInnerHTML={{
                    __html: t('lasta.disclaimer', {}),
                  }}
                />
              ) : (
                <FooterDisclaimerText>
                  {disclaimerOpen ? t(`footer.disclaimer.full`) : t(`footer.disclaimer.short`)}
                  <FooterDisclaimerButton customColor={colorAccent} onClick={handleClick}>
                    {disclaimerOpen ? t(`footer.disclaimer.less`) : t(`footer.disclaimer.more`)}
                  </FooterDisclaimerButton>
                </FooterDisclaimerText>
              )}
            </FooterDisclaimer>
          </FooterTop>
          <FooterBottom>
            <FooterInfo>
              <a href={`tel:${config.DESCRIPTOR_PHONE}`}>{config.DESCRIPTOR_PHONE}</a>
              {showPrivacyPolicyLink && (
                <NavLink to="/privacy-policy">{t(`footer.disclaimer.privacy`)}</NavLink>
              )}
              <a href={`mailto:${config.MAILER_SUPPORT_EMAIL}`}>{config.MAILER_SUPPORT_EMAIL}</a>
            </FooterInfo>
            <FooterCopy>
              © {year}{' '}
              <span>
                {config.TRADE_NAME}.{config.DESCRIPTOR_DOMAIN}
              </span>{' '}
              - {t(`footer.disclaimer.copy`)}
            </FooterCopy>
          </FooterBottom>
        </FooterWrapper>
      ) : (
        <FooterWrapper>
          <FooterInfo>
            {showPrivacyPolicyLink &&
              !['guard-tools.net', 'm-services.app'].includes(descriptorName) && (
                <NavLink target="_blank" className="privacy" to="/privacy-policy">
                  {t(`oldTemplate.footer.disclaimer.privacy`)}
                </NavLink>
              )}
            {descriptorName !== 'msp-bill.info' && (
              <FooterCopy>
                © {year}{' '}
                <span>
                  {config.TRADE_NAME}.{config.DESCRIPTOR_DOMAIN}
                </span>{' '}
                {t(`oldTemplate.footer.disclaimer.copy`)}
              </FooterCopy>
            )}
            <FooterContacts>
              <a href={`tel:${config.DESCRIPTOR_PHONE}`}>{config.DESCRIPTOR_PHONE}</a>
              <a href={`mailto:${config.MAILER_SUPPORT_EMAIL}`}>{config.MAILER_SUPPORT_EMAIL}</a>
            </FooterContacts>
            {descriptorName === 'msp-bill.info' && (
              <FooterAddressText>
                The site is owned and operated by Altercon Group s.r.o., reg. number 06746764,
                Londynska 730/59, Vinohrady, 120 00 Praha 2, Czech Republic
              </FooterAddressText>
            )}
          </FooterInfo>
          {!['guard-tools.net', 'm-services.app'].includes(descriptorName) && (
            <FooterDisclaimer>
              <FooterDisclaimerHeadline>
                {t(`oldTemplate.footer.disclaimer.headline`)}
              </FooterDisclaimerHeadline>
              <FooterDisclaimerText>
                <div
                  style={{ display: 'contents' }}
                  dangerouslySetInnerHTML={
                    disclaimerOpen
                      ? { __html: t(`oldTemplate.footer.disclaimer.full`) }
                      : { __html: t(`oldTemplate.footer.disclaimer.short`) }
                  }
                />
                <FooterDisclaimerButton customColor={colorAccent} onClick={handleClick}>
                  {disclaimerOpen
                    ? t(`oldTemplate.footer.disclaimer.less`)
                    : t(`oldTemplate.footer.disclaimer.more`)}
                </FooterDisclaimerButton>
              </FooterDisclaimerText>
            </FooterDisclaimer>
          )}
          {descriptorName === 'msp-bill.info' && (
            <FooterCopyContainer>
              © {year}{' '}
              <span>
                {config.TRADE_NAME}.{config.DESCRIPTOR_DOMAIN}
              </span>{' '}
              {t(`oldTemplate.footer.disclaimer.copy`)}
            </FooterCopyContainer>
          )}
        </FooterWrapper>
      )}
    </FooterSection>
  );
};

export default Footer;

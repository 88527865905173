import React, { memo } from 'react';
import { parseISO } from 'date-fns';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import config from '@/config.json';
import {
  CheckboxControl,
  CheckboxInput,
  CheckboxLabel,
  CheckboxNote,
  CheckboxWrapper,
  FormControl,
  FormControlInput,
  FormControlInputError,
  FormControlLabel,
} from './styled';
import 'react-datepicker/dist/react-datepicker.css';

const errorMessage = (error, name) => {
  const { t } = useTranslation();

  return (
    <FormControlInputError>
      {error && error.type === 'required' && t(`inputs.${name}.required`)}
      {error && error.type === 'pattern' && t(`inputs.${name}.format`)}
    </FormControlInputError>
  );
};

const CheckboxField = ({
  register = () => {},
  type,
  name,
  error = {},
  required = false,
  format = {},
  props = [],
}) => {
  const { t } = useTranslation();
  const template = config.TEMPLATE;
  const variant = `variant_${config.VARIANT}`;
  const oldTemplate = ['oldTemplate'].includes(template);
  const fieldTemplateClass = oldTemplate ? template : '';
  const fieldError = error.type === 'required' ? `is-required` : '';

  return (
    <CheckboxControl className={oldTemplate && template}>
      <CheckboxWrapper>
        <CheckboxInput
          type={type}
          name={name}
          id={name}
          {...register(name, {
            required,
            ...format,
          })}
          {...props}
        />
        <CheckboxLabel
          htmlFor={name}
          className={fieldTemplateClass}
          isError={fieldError}
          dangerouslySetInnerHTML={
            oldTemplate
              ? { __html: t(`oldTemplate.inputs.${name}.title`) }
              : { __html: t(`inputs.${name}.title`) }
          }
        />
      </CheckboxWrapper>
      <CheckboxNote
        className={`${template} policy-note`}
        {...(config.VARIANT && { templateVariant: variant })}
      >
        {oldTemplate ? t(`oldTemplate.inputs.${name}.notes`) : t(`inputs.${name}.notes`)}
      </CheckboxNote>
    </CheckboxControl>
  );
};

CheckboxField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  format: PropTypes.objectOf(PropTypes.objectOf(PropTypes.shape(RegExp))),
  error: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]),
      ),
    ]),
  ),
  register: PropTypes.func,
  props: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};

const InputField = ({
  register = () => {},
  type,
  name,
  error = {},
  required = false,
  format = {},
  className = '',
  onChange = () => {},
  value = '',
}) => {
  const { t } = useTranslation();
  const template = config.TEMPLATE;
  const oldTemplate = ['oldTemplate'].includes(template);

  return (
    <FormControl className={className}>
      {!oldTemplate && (
        <FormControlLabel htmlFor={name}>{t(`inputs.${name}.title`)}</FormControlLabel>
      )}
      <FormControlInput
        type={type}
        name={name}
        placeholder={
          oldTemplate
            ? t(`oldTemplate.inputs.${name}.placeholder`)
            : t(`inputs.${name}.placeholder`)
        }
        {...register(name, {
          required,
          ...format,
        })}
        {...(onChange && { onChange })}
        {...(name === 'amount' && { value })}
      />
      {error && errorMessage(error, name)}
    </FormControl>
  );
};

InputField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  format: PropTypes.objectOf(PropTypes.objectOf(PropTypes.shape(RegExp))),
  error: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]),
      ),
    ]),
  ),
  register: PropTypes.func,
  props: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
};

const DateField = ({
  register = () => {},
  name,
  onChange,
  selected,
  className = '',
  props = [],
}) => {
  const { t } = useTranslation();
  const template = config.TEMPLATE;
  const oldTemplate = ['oldTemplate'].includes(template);

  const onChangeRawCustom = (event) => {
    if (event.target.value) {
      const regEx = /[0-9-]+$/g;
      event.target.value = event.target.value.match(regEx, ''); // eslint-disable-line no-param-reassign
    }
  };

  const subtractYears = (date, years) => {
    date.setFullYear(date.getFullYear() - years);
    return date;
  };

  return (
    <FormControl className={className}>
      {!oldTemplate && (
        <FormControlLabel htmlFor={name}>{t(`inputs.${name}.title`)}</FormControlLabel>
      )}
      <DatePicker
        dateFormat="yyyy-MM-dd"
        {...register(name)}
        {...props}
        onChangeRaw={(e) => onChangeRawCustom(e)}
        minDate={subtractYears(new Date(), 2)}
        maxDate={parseISO(new Date().toISOString().split('T')[0])}
        onChange={onChange}
        placeholderText={
          oldTemplate
            ? t(`oldTemplate.inputs.${name}.placeholder`)
            : t(`inputs.${name}.placeholder`)
        }
        selected={selected}
        className="datepicker"
      />
    </FormControl>
  );
};

DateField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  register: PropTypes.func,
  props: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};

export const Field = memo(({ type = null, ...props }) => {
  switch (type) {
    case 'date':
      return <DateField type={type} {...props} />;
    case 'checkbox':
      return <CheckboxField type={type} {...props} />;
    default:
      return <InputField type={type} {...props} />;
  }
});

Field.propTypes = {
  type: PropTypes.string,
};

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel } from '@Components/UI/Input/styled';
import { TextAreaControl, TextAreaField } from '@Components/UI/TextArea/styled';
import PropTypes from 'prop-types';
import config from '@/config.json';

const TextArea = ({ register = () => {}, name = '', props = [] }) => {
  const { t } = useTranslation();
  const template = config.TEMPLATE;
  const oldTemplate = ['oldTemplate'].includes(template);

  return (
    <TextAreaControl>
      {!oldTemplate && <FormControlLabel>{t(`inputs.${name}.title`)}</FormControlLabel>}
      <TextAreaField
        {...register(name)}
        placeholder={
          oldTemplate
            ? t(`oldTemplate.inputs.${name}.placeholder`)
            : t(`inputs.${name}.placeholder`)
        }
        {...props}
      />
    </TextAreaControl>
  );
};

TextArea.propTypes = {
  name: PropTypes.string,
  props: PropTypes.arrayOf(PropTypes.string),
  register: PropTypes.func,
};

export default memo(TextArea);

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import config from '@/config.json';
import { ArrowIcon } from '@Components/UI/Icons';
import {
  FaqHeadline,
  FaqHeadlineWrapper,
  FaqItem,
  FaqTxt,
  Section,
  SectionContainer,
  SectionFaqList,
  SectionHeadline,
} from './styled';
import list from './faqList.json';

const LastaTemplate = () => {
  const { t } = useTranslation();
  const template = config.TEMPLATE;
  const variant = `variant_${config.VARIANT}`;

  return (
    <Section id="faq" variant={template} {...(config.VARIANT && { templateVariant: variant })}>
      <SectionContainer>
        <SectionHeadline variant={template} {...(config.VARIANT && { templateVariant: variant })}>
          {t(`lasta.faq.headline`)}
        </SectionHeadline>
        <SectionFaqList>
          {list?.map((item) => {
            const [isOpen, setIsOpen] = useState(false);
            return (
              <FaqItem
                key={item.id}
                variant={template}
                {...(config.VARIANT && { templateVariant: variant })}
                className={isOpen && 'is-open'}
              >
                <FaqHeadlineWrapper onClick={() => setIsOpen(!isOpen)}>
                  <FaqHeadline
                    variant={template}
                    {...(config.VARIANT && { templateVariant: variant })}
                    dangerouslySetInnerHTML={{
                      __html: t(`lasta.faq.list.${item.headline}`, {
                        descriptor_email: config.MAILER_SUPPORT_EMAIL.toLowerCase(),
                        descriptor: config.TRADE_NAME.toLowerCase(),
                        descriptor_domain: config.DESCRIPTOR_DOMAIN.toLowerCase(),
                      }),
                    }}
                  />
                  <ArrowIcon />
                </FaqHeadlineWrapper>
                <FaqTxt
                  dangerouslySetInnerHTML={{
                    __html: t(`lasta.faq.list.${item.txt}`, {
                      descriptor_email: config.MAILER_SUPPORT_EMAIL.toLowerCase(),
                      descriptor: config.TRADE_NAME.toLowerCase(),
                      descriptor_domain: config.DESCRIPTOR_DOMAIN.toLowerCase(),
                    }),
                  }}
                />
              </FaqItem>
            );
          })}
        </SectionFaqList>
      </SectionContainer>
    </Section>
  );
};
export default LastaTemplate;

import React from 'react';
import config from '@/config.json';
import { LastaLogo as LogoSvg } from '@Components/UI/Logos';
import { DefaultLogo, LastaLogo, OldLogo } from './styled';

const Logo = ({ variant, templateVariant, isFooter }) => {
  const logoSize = config.DESCRIPTOR_NAME.length > 11 ? 'is-small' : '';
  const logoSup = config?.DESCRIPTOR_LOGO;
  const colorAccent = config?.COLOR_ACCENT;

  const defaultTemplate = () => (
    <DefaultLogo customColor={colorAccent} className={logoSize}>
      {logoSup ? (
        <p dangerouslySetInnerHTML={{ __html: logoSup }} />
      ) : (
        <>
          {config.TRADE_NAME}
          <span>.{config.DESCRIPTOR_DOMAIN}</span>
        </>
      )}
    </DefaultLogo>
  );

  const oldTemplate = () => (
    <OldLogo>
      {config.TRADE_NAME}.{config.DESCRIPTOR_DOMAIN}
    </OldLogo>
  );

  const lastaTemplate = () => (
    <LastaLogo templateVariant={templateVariant} className={isFooter && 'is-footer'}>
      <LogoSvg />
      <span>{config.LOGO_DOMAIN}</span>
    </LastaLogo>
  );

  switch (variant) {
    case 'lasta':
      return lastaTemplate();
    case 'oldTemplate':
      return oldTemplate();
    default:
      return defaultTemplate();
  }
};

export default Logo;

import styled from 'styled-components';

export const MobileBurger = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
  border-radius: 8px;

  i {
    position: absolute;
    display: block;
    width: 20px;
    height: 2px;
    background-color: ${(props) => props.theme.headerText};
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.3s ease-in-out;

    &:nth-child(1) {
      top: 13px;
    }

    &:nth-child(2) {
      top: 19px;
    }

    &:nth-child(3) {
      bottom: 13px;
    }
  }

  &.oldTemplate {
    i {
      height: 4px;
      width: 30px;
      border-radius: 4px;
      background-color: #fff;

      &:nth-child(1) {
        top: 7px;
      }

      &:nth-child(2) {
        top: 17px;
      }

      &:nth-child(3) {
        bottom: 9px;
      }
    }

    @media (max-width: 420px) {
      width: 28px;
    }
  }

  &.lasta {
    i {
      background-color: ${({ theme, templateVariant }) =>
        templateVariant && theme.burger[templateVariant]};
    }
  }
`;

export const MobileNav = styled.div`
  width: 100%;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: fit-content;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-right: 10px;
  background: ${(props) => props.theme.languageBg};
  border-radius: 8px 0 8px 8px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition:
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;

  a {
    padding: 10px 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: ${(props) => props.theme.headerText};
    text-align: right;
    white-space: nowrap;
  }

  &.lasta {
    background-color: ${({ theme, templateVariant }) =>
      templateVariant && theme.burgerOpen[templateVariant]};

    a {
      color: ${({ theme, templateVariant }) =>
        templateVariant && theme.burgerOpenNav[templateVariant]};
    }
  }

  &.oldTemplate {
    border-radius: 0;
    top: 100%;
    align-items: flex-start;
    padding: 10px 20px;
    height: 100vh;

    a {
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

export const MobileNavWrapper = styled.div`
  position: relative;
  margin-left: 8px;

  &.oldTemplate {
    position: static;
  }

  &.is-open {
    ${MobileNav} {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }

    ${MobileBurger} {
      background-color: ${(props) => props.theme.languageBg};

      i {
        background-color: ${(props) => props.theme.accent};

        &:nth-child(1) {
          top: 50%;
          width: 22px;
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:nth-child(2) {
          width: 0;
        }

        &:nth-child(3) {
          top: 50%;
          width: 22px;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }

      &.lasta {
        background-color: ${({ theme, templateVariant }) =>
          templateVariant && theme.burgerOpen[templateVariant]};

        i {
          background-color: ${({ theme, templateVariant }) =>
            templateVariant && theme.burgerOpenNav[templateVariant]};
        }
      }

      &.oldTemplate {
        i {
          background-color: #ffffff;

          &:nth-child(1) {
            top: 50%;
            width: 30px;
            transform: translate(-50%, -50%) rotate(45deg);
          }

          &:nth-child(3) {
            top: 50%;
            width: 30px;
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }
    }
  }
`;

import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import * as PropTypes from 'prop-types';
import GlobalStyle from '@/styles';
import config from '@/config.json';
import { themes } from '@/themes';
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import HelmetComponent from '@Components/Helmet';

const RootComponent = ({ children }) => {
  const [loadedContent, setLoadedContent] = useState(false);

  const theme = themes[config.TEMPLATE ?? 'default'][config.THEME ?? 'dark'];
  const language = config.DEFAULT_LANG;

  useEffect(() => {
    i18n
      .use(Backend)
      .use(initReactI18next)
      .init({
        fallbackLng: 'en',
        lng: localStorage.getItem('lang') || `${language}`,
        backend: {
          loadPath: `/translations/{{lng}}.json`,
        },
        ns: ['translations'],
        defaultNS: 'translations',
      })
      .then(() => setLoadedContent(true));
  }, [language]);

  return loadedContent ? (
    <ThemeProvider theme={theme}>
      <HelmetComponent />
      <GlobalStyle />
      {children}
    </ThemeProvider>
  ) : null;
};

RootComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default RootComponent;

import React from 'react';
import { Content, ContentTxt, ContentWrapper } from '@Components/ContentSection/styled';
import * as PropTypes from 'prop-types';
import config from '@/config.json';
import { useTranslation } from 'react-i18next';

const ContentSection = ({ text }) => {
  const { t } = useTranslation();
  const template = config.TEMPLATE;
  const variant = `variant_${config.VARIANT}`;

  return (
    <Content className={template} {...(config.VARIANT && { templateVariant: variant })}>
      <ContentWrapper>
        <ContentTxt
          dangerouslySetInnerHTML={{
            __html: t(`${text}`, {
              descriptor: config.TRADE_NAME.toLowerCase(),
              descriptor_app: config.DESCRIPTOR_NAME,
              descriptor_date: config?.POLICY_DATE,
              email: config.MAILER_SUPPORT_EMAIL.toLowerCase(),
            }),
          }}
        />
      </ContentWrapper>
    </Content>
  );
};

ContentSection.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ContentSection;

import React from 'react';
import MainScreen from '@Containers/MainScreen';
import WhoScreen from '@Containers/Who';
import QuestionsScreen from '@Containers/Questions';
import TermsScreen from '@Containers/Terms';
import config from '@/config.json';

const Main = () => {
  const template = config.TEMPLATE;

  return (
    <>
      <MainScreen variant={template} />
      <WhoScreen variant={template} />
      <QuestionsScreen variant={template} />
      <TermsScreen variant={template} />
    </>
  );
};

export default Main;
